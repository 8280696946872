.three-modules {
    padding: 4% 2.5%;
    width: 30%;
    float: left;
    box-sizing: border-box;
    img {
      width: 175px;
    }
  }
  
  @media (max-width: 993px) {
    .three-modules {
      width: 40%;
      float: left;
    }
  }
  @media (max-width: 650px) {
    .three-modules {
      width: 90%;
      float: left;
    }
  }
.about-us-box {
    margin: 30px 1.5%;
    height: auto;
    box-sizing: border-box;
}
.card-style {
	background: #fff;
  --tw-shadow: 0px 4px 15px rgba(3, 24, 52, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0px 4px 15px var(--tw-shadow-color), 0px 2px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 20px;
}