@import 'app/styling/variables.scss';
.discoveryContainer {
    display: flex;
    align-items: flex-start;
    height: 100vh;
    margin: 10vh 5% 0 5%;
    @media (max-width: $screen-mobile) {
      margin: unset;
      width: 100%;
    }
}
.interactionContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.headerSection {
    width: 350px;
    height: 100%;
    .headerText {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
    h1 {
      margin-bottom: 15px;
    }
    .subText {
      cursor: default;
    }
    @media (max-width: $screen-sm) {
        display: none;
    }
}
.sideMenu {
    width: 250px;
    position: sticky;
    top: 0;
    height: 100%;
    overflow-y: auto;
    margin-left: 20px;
  
    ul {
      list-style-type: none;
      padding: 0;
      display: flex;
      flex-direction: column;
  
      li {
        margin: 10px 0;
  
        a {
          text-decoration: none;
          color: $untitledui-text-quarternary;
          font-size: large;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 10px;
          &:hover {
            color: $brand-primary;
          }
          cursor: pointer;
        }
      }
    }
}
.mainContent {
    flex-grow: 1;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    overflow-x: hidden;
}
.noVideos {
    height: 85vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: medium;
    text-align: center;
    color: white;
    font-weight: 500;
    aspect-ratio: 0.5625 / 1;
    max-width: calc(76.25px + 56.25vh);
    background: linear-gradient(to right, #2E4C4D, #508484);
    @media (min-width: $screen-mobile) {
      border-radius: 20px;
    }
    @media (max-width: $screen-mobile) {
      width: 100%;
      height: 100vh;
    }
  .communityName {
    color: $brand-tertiary;
  }
}
.videoContainer {
  position: relative;
  height: 85vh;
  display: flex;
  flex-direction: column; /* Align videos vertically */
  overflow-y: scroll; /* Enable vertical scrolling */
  scroll-snap-type: y mandatory; /* Snap vertically */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  @media (max-width: $screen-mobile) {
    width: 100%;
    height: 100vh;
  }
}
.fullScreen {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #000; /* Optional for effect */
}

.videoContainer::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.videoWrapper {
  scroll-snap-align: start; /* Snap video to start */
  transition: transform 200ms cubic-bezier(0.25, 1, 0.5, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media (max-width: $screen-mobile) {
    width: 100%;
  }
}

.videoNavButtons {
  @media (max-width: $screen-mobile) {
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin-bottom: 0px;
  }
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 10px;
  margin-top: auto;
  margin-bottom: 70px;

  button {
    z-index: 2;
    width: 60px;         /* Set a fixed width */
    height: 60px;        /* Set a fixed height equal to width */
    background-color: rgba(0, 0, 0, 0);
    color: #107569;
    border: none;
    border-radius: 50%;  /* Make it a circle */
    cursor: pointer;
    display: flex;        /* Use flex to center text */
    align-items: center;  /* Center vertically */
    justify-content: center; /* Center horizontally */
    transition: background-color 0.3s ease;

    /* Desktop hover effect */
    &:hover {
      background-color: $brand-background-gray;
    }

    /* Mobile tap effect */
    &:active {
      background-color: $brand-background-gray;
    }
  }
  /* Remove hover effect on mobile using a media query */
  @media (hover: none) {
    button:hover {
      background-color: rgba(0, 0, 0, 0); /* No hover effect on mobile */
    }
  }
}

@media (max-width: $screen-mobile) {
  .videoNavButtons {
      right: 2%;
      bottom: 13%;
      button {
        color: white;
        background-color: rgba(0, 0, 0, 0);
      }
  }
}

.notification {
  position: fixed; /* Fixed positioning to keep it in view */
  top: 80px; /* Position it from the top */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Offset by 50% of its width for perfect centering */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding around the text */
  border-radius: 5px; /* Rounded corners */
  z-index: 1000; /* Make sure it's above other content */
  transition: opacity 0.5s ease; /* Smooth fade-in and fade-out */
}