// Colors
$brand-white: #fff;

@mixin gradient-background(){
    background: #499bea;
    background: -moz-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, #499bea), color-stop(100%, #798eea));
    background: -webkit-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
    background: -o-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
    background: -ms-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
    background: linear-gradient(135deg, #499bea 0%, #798eea 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#499bea', endColorstr='#798eea', GradientType=1 );
}

// Style begins
.colorlib-cta {
	.overlay-cta {
		z-index: 0;
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		// @include gradient-background();
		opacity: .96;
	}
	.colorlib-heading-cta{
		margin-top: 30px;
		margin-left: 5%;
		margin-right: 5%;
		h2{
			color: #808080;
            font-size: 30px;
			font-family: "sofia-pro", sans-serif;
			font-weight: 200;
			line-height: 1.3;
		}
		p{
			font-size: 20px;

		}
		.colorlib-header {
			color: #303133 !important;
			font-weight: 300 ;
			font-size: 65px;
			margin-bottom: 30px;
			position: relative;

		}
	}
	.call-to-action {
    	padding-bottom: 4%;
		Button {
			margin-top: 1%;
			height: 50px;
			width: 200px;
		}
	}
	@media screen and (max-width: 768px) {
		.call-to-action {
			h2 {
				font-size: 20px;
				margin-left: 30px;
				margin-right: 30px;
			}
			Button {
				// height: 50px;
				margin-top: 1%;
				width: 150px;
			}
		}
	}
	img {
		width: 40%;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		@media (max-width: 500px) {
			width: 300px;
		}
	}
}
