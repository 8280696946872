h2{
	color: #303133;
	font-family: "sofia-pro", sans-serif;
	font-weight: 400;
	font-style: normal; }
  
  .video-hero {
	  background-size: cover;
	  position: relative;
	  background-repeat: no-repeat;
	  z-index: 0; 
	  }
	  @media screen and (max-width: 768px) {
		.video-hero {
		  height: inherit;
		  } }
	  .video-hero .display-t {
		display: table;
		width: 100%;
		position: relative;
		z-index: 2;}
		.video-hero .display-t.display-t2 {
		  height: 500px; }
	  .video-hero .display-tc {
		display: table-cell;}
		.video-hero .display-tc .holder {
		  font-family: "Poppins", Arial, sans-serif;
		  font-size: 12px;
		  text-transform: uppercase;
		  letter-spacing: 7px; }
		  .video-hero .display-tc .holder span {
			position: relative; }
		.video-hero .display-tc p.breadcrumbs {
		  font-size: 14px; }
		  .video-hero .display-tc p.breadcrumbs span {
			padding-right: 10px;
			color: rgba(255, 255, 255, 0.5); }
			.video-hero .display-tc p.breadcrumbs span a {
			  color: rgba(255, 255, 255, 0.9); }
		.video-hero .display-tc .btn-custom {
		  font-size: 16px; }
  
  /* ---------------Bootstrap Begins Here---------------*/
  
  
  .container {
	  margin-right: auto;
	  margin-left: auto;
	  padding-left: 15px;
	  padding-right: 15px;
	  }
	  .container:before, .container:after {
	  content: " ";
	  display: table;
	  }
	  .container:after {
	  clear: both;
	  }
	  @media (min-width: 768px) {
	  .container {
		  width: 750px;
	  }
	  }
	  @media (min-width: 992px) {
	  .container {
		  width: 970px;
	  }
	  }
	  @media (min-width: 1200px) {
	  .container {
		  width: 1170px;
	  }
	  }
	  
	  .container-fluid {
	  margin-right: auto;
	  margin-left: auto;
	  padding-left: 15px;
	  padding-right: 15px;
	  }
	  .container-fluid:before, .container-fluid:after {
	  content: " ";
	  display: table;
	  }
	  .container-fluid:after {
	  clear: both;
	  }
  
  @media (min-width: 992px) {
	  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
		  float: left;
	  }
	  .col-md-12 {
		  width: 100%;
		}
  }
  @media (max-width: 992px) {
	.page-image-header {
	  display: none;
	}
	}
  .page-header-container {
	display: flex; /* Use Flexbox to align items horizontally */
	justify-content: center;
	align-items: stretch; /* Align items vertically in the center */
	padding-bottom: 20px;
	.page-header {
		text-align: center;
		margin-bottom: 20px;
		margin-top: 0px;
	}
	.page-subheader {
		margin-bottom: 10px;
		line-height: 1.4;
		font-weight: 400;
		font-size: 25px;
	}
	padding-bottom: 50px;
  }
  .page-header-wrapper {
	display: flex;
	flex-direction: column;
	flex: 1; /* Take up all available space */
	margin-top: 40px;
	margin-top: 80px;
		.page-header {
			font-size: 60px;
			font-weight: 600; 
			line-height: 1.2;
			color: #fff;
		}
		.page-subheader {
			font-size: 20px;
		}
		@media (max-width: 1569px) {
		
			.page-header {
				font-size: 50px;
			}
		}
		@media (max-width: 1433px) {
			.page-header {
				font-size: 50px;
			}
			.page-subheader {
				font-size: 20px;
			}
		}
		@media (max-width: 1017px) {
			.page-header {
				font-size: 45px;
			}
			.page-subheader {
				font-size: 15px;
			}
		}
		@media (max-width: 848px) {
			.page-header {
				font-size: 30px;
			}
			.page-subheader{
				font-size: 15px;
			}
		}
		@media (max-width: 769px) {
			margin-top: 100px;
			.page-header {
				font-size: 30px;
			}
			.page-subheader {
				font-size: 15px;
			}
		}
  }
  .page-image-header{
	display: flex;
	position: relative;
	justify-content: center;
	overflow: hidden;
	width: 100%;
	@media (max-width: 1133px) {
		height: 400px;
	}
	.hero {
		height: auto;
		width: 80%;
		transform: scale(1.25);
		margin-top: -8%;
		margin-bottom: -8%;
		object-fit: cover;
		object-position: center;
		@media (max-width: 650px) {
			margin-top: -20%;
			margin-bottom: -20%;
		}
	}
  }
  
  
  /* ---------------Bootstrap Ends Here---------------*/
  .page-header-btn {
	margin-top: 20px;
	height: 50px;
	font-size: 15px;
	height: 50px;
	width: 200px;
	line-height: 1;
	@media (max-width: 769px) {
		height: 40px;
		font-size: 12px;
	}
  }