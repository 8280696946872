@import 'app/styling/variables.scss';

// Overrides
$grid-gutter-width: 40px !default; 
$border-radius-base:  4px !default;
$padding-base-vertical: 14px !default;
$brand-tertiary: #499bea !default;
$brand-footer-color: #323232;

$brand-white: #fff;
$brand-black: #000;
$brand-darker: #303133;
$brand-gray: #ccc;
$brand-lighter: #e9e9e9;
$brand-body-color: #e8e8e8;
$brand-selection-color: #f9f6f0;
$brand-overlay-color: #4c4a8a;
$brand-bg-color: $brand-white;

$input-border-focus:  $brand-primary !default;
$form-group-margin-bottom: 30px !default;

.colored-background {
  background-color: #68a3a3;
  @media (max-width: 767px) {
    background-color: #5D9898;
  }
    h2 {
        color: #fff !important;
    }
    h3.subheading {
        color: #fff;
    }
}

.project-connections-img {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    display: flex;
    @media (max-width: 500px) {
        width: 40%;
    }
}

.header-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pitch-simplification-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    picture {
      display: flex;
      justify-content: center;
      align-self: center;

      img {
        padding: 2% 0%;
        width: 70%;
        border-radius: 20px;
        @media (max-width: 1024px) {
            width: 90%;
        }
      }
    }
}
.network-benefits-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    padding: 4% 0%;
    width: 80%;
  }
}
.skill-tags-section {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 60%;
  .skill-tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    div {
      border: solid $brand-secondary 2px;
      border-radius: 10px;
      background-color: $brand-secondary;
      color: #fff;
      padding: 3px 15px;
    }
  }
}
.join-now-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}
 
.video-explanation {
  padding: 2% 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 40px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
  .cropped-swiping-video {
    position: relative;
    width: fit-content;
    height: 600px;
    overflow: hidden;
    border-radius: 30px;
    @media (max-width: 1133px) {
      height: 400px;
    }
    .hero {
      height: 100%;
    }
  }
  .explanation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-weight: 600;
      font-size: 40px;
      color: #2e4c4d;
    }
    @media screen and (max-width: 768px) {
      h1 {
        font-size: 25px; 
      }
    }
  }  
}

.promptContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  .icon {
      width: 40px;
      height: 40px;
      padding: 5px;
      color: $untitledui-text-quarternary-hover;
      // border: 1px solid $untitledui-text-tertiary;
      border-radius: 5px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
  .promptSubcontainer {
      padding-left: 20px;
  }
}