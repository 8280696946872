@import 'app/styling/variables.scss';
.userProfilePageContainer {
    padding: 10vh 0 5vh 0;
    height: 100vh;
    display: grid;
    grid-template-columns: 40% 58%;
    column-gap: 2%;
    box-sizing: border-box;
    margin: 0 5%;
    max-width: 2000px;
    gap: 20px;
    @media (max-width: 1023px) {
        grid-template-columns: 100%;
        height: unset;
    }
    @media (max-width: 600px) {
        margin: 0 0;
        height: unset;
        padding: 0vh 0 5vh 0;
    }
}
.dataContainer {
    height: 100%;
    overflow-y: auto;
    border: 2px solid #EBEDED;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
}
.videoContainer {
    position: relative;
    display: flex;
    flex-direction: column; /* Stack the video and button vertically */
    justify-content: center;
    align-items: center;
    max-height: 86vh;
    max-width: 100%;
    box-sizing: border-box;
    gap: 20px;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    margin-top: auto; /* Pushes the buttons to the bottom of the container */
    margin-bottom: 20px;
}

.promptContainer {
    display: flex;
    flex-direction: row;
    .icon {
        width: 30px;
        height: 30px;
        padding: 5px;
        color: $untitledui-text-quarternary-hover;
        // border: 1px solid $untitledui-text-tertiary;
        border-radius: 5px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
    .promptSubcontainer {
        padding-left: 20px;
    }
}
.promptTitle {
    color: $untitledui-text-primary;
    text-align: center;
    font-weight: 500;
    margin-top: 60px;
    @media (max-width: 600px) {
        font-size: 17px;
    }
}

.promptText {
    font-size: 20px;
    color: $untitledui-text-primary;
    display: flex;
    align-items: center;
    gap: 10px;
}

.promptSubtext {
    color: $untitledui-text-tertiary;
}

.videoPrompt {
    padding: 40px 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Keeps space between content and buttons */
    gap: 40px;
    @media (max-width: $screen-mobile) {
        padding: 40px 10%;
    }
}

.buttonSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}
.errorMessage {
    color: red;
    font-size: small;
    text-align: center;
}
.successMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $untitledui-text-quarternary-hover;
    .icon {
        width: 50px;
        height: 50px;
        padding: 5px;
        color: $brand-secondary;
    }
}
.promptSubText {
    font-size: 15px;
    color: gray;
}