@import 'app/styling/variables.scss';
.margined-container {
  width: 90%;
  margin: 0 auto;
}

.colorlib-services {
  padding: 2em 0 4em;
  @media screen and (max-width: 768px) {
    padding: 0 0 7em 0;
}
  clear: both; }
.colorlib-bg-white {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none; }
.desc {
  h3 {
    color: #303133;
    font-family: "sofia-pro", sans-serif;
    font-weight: 300;
    line-height: 1.3;
    font-size: 30px;
  }
  div {
    box-sizing: border-box;
    font-size: 20px;
    font-family: "sofia-pro", sans-serif;
    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
  }
}
.boxed-out-module {
  border: solid;
  border-color: $brand-primary;
  border-radius: 25px;
  padding: 30px;
  border-width: 8px;
  @media (max-width: 993px) {
    margin-left: 11%;
  }
}
.module {
  padding: 60px 30px 60px 30px;
  width: 33%;
  float: left;
  @media (max-width: 993px) {
    float: left;
    width: 80%;
  }
}

@media (max-width: 993px) {
  .two-thirds-parent-module {
    float: left;
    width: 100%;
      .module {
        margin-top: 10%;
        width: 100%;
      }
    }
}
.split-between {
  width: 8px;
  height: 375px;
  border-radius: 25px;
  float: left;
  background-color: $brand-primary;  
  @media (max-width: 993px) {
    width: 80%;
    height: 8px;
    margin-left: 11%;
  }
}