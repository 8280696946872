.divider-container {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.or-divider {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.GoogleLogin {
	width: 100%;
}

.sign-in-password-toggle {
	position: absolute;
	right: 75px;
	top: 101px;
	transform: translateY(-50%);
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
}

.default-form-format {
	display: flex;
 	flex-direction: column;
 	gap: 10px;
	background-color: white;
	padding: 2rem 3.5rem 2rem 3.5rem;
	width: 500px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	.form-buttons {
		max-height: 40px;
		min-width: 87px;
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
	}
	@media (max-width: 500px) {
		width: -webkit-fill-available;
	}
}

.reset-password {
	font-size: 12px;
	position: relative;
}

.signup-message {
		font-size: 13px;
		display: flex;
		gap: 5px;
		margin-top: 5px;
		.signup-link {
			cursor: pointer;
			text-decoration: underline;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: -20px;
		.signup-link {
			cursor: pointer;
			font-weight: 700;
		}
	}
}