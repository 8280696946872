@import 'app/styling/variables.scss';
.events-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .event-item {
        border-radius: 30px;
        border: 3px solid #50848400;
        &:hover {
            cursor: pointer;
            border: 3px solid white;;
        }
        img {
            max-height: 450px;
            max-width: 450px;
            height: auto;
            width: auto;
            @media (max-width: 768px) {
                max-height: 300px;
                max-width: 300px;
            }
        }
        h3 {
            margin: 0px;
            padding-top: 10px;
            text-align: center;
            color: white;
        }
        p {
            margin: 0px;
            font-size: 1.5rem;
            text-align: center;
            color: white;
        }
        .event-details {
            padding-bottom: 10px;
        }
    }
}