// Variables
//== Colors
$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7

// Small screen / tablet
$screen-sm:                  1050px !default;
$grid-gutter-width: 40px !default; 
$border-radius-base:  4px !default;
$padding-base-vertical: 14px !default;

$brand-primary: #4aca85 !default;
$brand-secondary: #798eea !default;
$brand-tertiary: #499bea !default;
$brand-footer-color: #323232;

$brand-white: #fff;
$brand-black: #000;
$brand-darker: #303133;
$brand-gray: #ccc;
$brand-lighter: #e9e9e9;
$brand-body-color: #e8e8e8;
$brand-selection-color: #f9f6f0;
$brand-overlay-color: #4c4a8a;
$brand-bg-color: $brand-white;

$input-border-focus:  $brand-primary !default;
$form-group-margin-bottom: 30px !default;
// Mixin
@mixin translateX($translatex) {
	transform: translateX($translatex);
}
@mixin transition($transition) {
    transition:         all $transition ease;
}
@mixin icomoon() {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
}
.colorlib-nav-toggle {
	width: 30px;
	height: 30px;
	top: 0.8em !important; }
	.colorlib-nav-toggle.active i::before, .colorlib-nav-toggle.active i::after {
		background: #303133; }
	.colorlib-nav-toggle:hover, .colorlib-nav-toggle:focus, .colorlib-nav-toggle:active {
		outline: none;
		border-bottom: none !important; }
	.colorlib-nav-toggle i {
		position: relative;
		display: inline-block;
		width: 25px;
		height: 2px;
		color: #252525;
		font: bold 14px/.4 Helvetica;
		text-transform: uppercase;
		text-indent: -55px;
		background: #252525;
		transition: all .2s ease-out; }
		.colorlib-nav-toggle i::before, .colorlib-nav-toggle i::after {
		content: '';
		width: 25px;
		height: 2px;
		background: #252525;
		position: absolute;
		left: 0;
		transition: all .2s ease-out; }
	.colorlib-nav-toggle.colorlib-nav-white > i {
		color: #fff;
		background: #fff; }
		.colorlib-nav-toggle.colorlib-nav-white > i::before, .colorlib-nav-toggle.colorlib-nav-white > i::after {
		background: #fff; }
	
	.colorlib-nav-toggle i::before {
	top: -7px; }
	
	.colorlib-nav-toggle i::after {
	bottom: -7px; }
	
	.colorlib-nav-toggle:hover i::before {
	top: -10px; }
	
	.colorlib-nav-toggle:hover i::after {
	bottom: -10px; }
	
	.colorlib-nav-toggle.active i {
	background: transparent; }
	
	.colorlib-nav-toggle.active i::before {
	top: 0;
	-webkit-transform: rotateZ(45deg);
	-moz-transform: rotateZ(45deg);
	-ms-transform: rotateZ(45deg);
	-o-transform: rotateZ(45deg);
	transform: rotateZ(45deg);
	background: #fff; }
	
	.colorlib-nav-toggle.active i::after {
	bottom: 0;
	-webkit-transform: rotateZ(-45deg);
	-moz-transform: rotateZ(-45deg);
	-ms-transform: rotateZ(-45deg);
	-o-transform: rotateZ(-45deg);
	transform: rotateZ(-45deg);
	background: #fff; }

#colorlib-offcanvas {
	position: fixed;
	z-index: 1901;
	width: 270px;
	background-color: #191E24;
	top: 0;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 75px 25px 40px 25px;
	overflow-y: auto;
	display: none;
	@include translateX(270px);
	@include transition(.5s);
	@media screen and (max-width: $screen-sm){
		display: block;
	}
	.offcanvas & {
		@include translateX(0px);
		height: 100vh;
	}
	a {
		color: rgba(255,255,255,.5);
		&:hover {
			color: rgba(255,255,255,.8);
		}
	}
	ul {
		padding: 0;
		margin: 0;
		li {
			padding: 5px 0px;
			margin: 0;
			list-style: none;
			display: list-item;
			> ul {
				display: none;
			}
			&.offcanvas-has-dropdown {
				> a {
					display: block;
					position: relative;
					&:after {
						position: absolute;
						right: 0px;
						@include icomoon;
						content: "\e0c5";
						font-size: 20px;
						color: rgba(255,255,255,.2);
						@include transition(.5s);
					}
				}
				&.active {
					a {
						&:after {
							-webkit-transform: rotate(-180deg);
							-moz-transform: rotate(-180deg);
							-ms-transform: rotate(-180deg);
							-o-transform: rotate(-180deg);
							transform: rotate(-180deg);
						}
					}
				}
			}
			&.btn-cta {
				margin-top: 5px;
				a {
					border-bottom: 1px solid $brand-primary;
					padding-bottom: 5px;
				}
			}
		}
	}
}
.dropdown-icon {
	position: absolute;
	width:11px;
	top: 41%;
	right: -3%;
	color: rgba(255, 255, 255, 0.5);
}
.menu-division {
	border-top: 1px solid #ccc;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.5);
	margin: 20px 0px;
}
.fixed-body {
	position: fixed;
	width: 100%;
	overflow: hidden;
}
.closed {
	display: none;
}
.profile-container-mobile {
	cursor: pointer;
    display: flex !important;
    align-items: center;
	justify-content: center;
	background-color: #80808042;
	border-radius: 20px;
	padding-top: 20px !important;
	padding-bottom: 15px !important;
	margin-bottom: 10px !important;
}
.profile-circle-mobile {
	align-items: center;
	justify-content: center;
	background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
	border: 1px solid #29313c;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	cursor: pointer;
	position: relative;
    margin-right: 5px; /* Adjusted from 100px */
	img {
		border-radius: 50%;
		width: 32px;
		height: 32px;
	}
}

.user-name {
	color: #f8f9fa;
	margin-left: 5px;
}
  

  