$brand-secondary: #798eea !default;
$brand-white: #fff;
$brand-overlay-color: #4c4a8a;
.row-pb-lg {
	padding-bottom: 7em !important;
}
#about-paragraph {
	padding-top: 7em !important;
}
.boxes-wrapper {
    padding: 2em 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}