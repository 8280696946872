@import 'app/styling/variables.scss';
.minor-note-section {
	background-color: $brand-secondary;
    color: white;
    text-align: center;
    font-size: 20px;
    padding-top: 2%;
    padding-bottom: 2%;
	font-weight: 300;
		@media (max-width: 1433px) {
			font-size: 20px;
		}
		@media (max-width: 1017px) {
			font-size: 15px;
		}
		@media (max-width: 848px) {
			font-size: 15px;
		}
		@media (max-width: 769px) {
			font-size: 15px;
		}
	div {
		margin-left: 8%;
		margin-right: 8%;
	}
}