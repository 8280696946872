@import 'app/styling/variables.scss';
.videoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    border-radius: 20px;
    overflow: hidden;
    box-sizing: border-box;
    height: fit-content;
    aspect-ratio: 0.5625 / 1;

    @media (max-width: 1023px) {
        height: 85vh;
    }
    @media (max-width: 600px) {
        height: 100%;
        width: 100%;
        border-radius: 0px;
    }
    .videoWrapper {
        width: 100%;
        max-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    video {
        border-radius: 20px;
        @media (min-width: 1024px) {
            // width: -webkit-fill-available !important;
        }
        @media (min-width: 1024px) {
            max-height: calc(-92px + 100vh);
            width: 100%;
        }
        @media (max-width: 600px) {
            border-radius: 0px;
            width: 100%;
        }
    }
}
.noVidMessage {
    text-align: center;
    padding: 0px 50px;
}

.loadingIcon {
    border: 3px solid $brand-secondary;
    border-radius: 100%;
    padding: 40px;
}