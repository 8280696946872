@import 'app/styling/variables.scss';
.module.without-border {
	@media (max-width: 993px) {
		width: 100% !important;
	}
}
.middle-icon {
	margin-top: 25%;
	border: solid;
    border-color: $brand-primary;
    border-radius: 25px;
    padding: 30px;
    border-width: 8px;
}
.card-style {
	background: #fff;
    --tw-shadow: 0px 4px 15px rgba(3, 24, 52, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 4px 15px var(--tw-shadow-color), 0px 2px 4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-radius: 20px;
}
