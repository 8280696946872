@import 'app/styling/variables.scss';
.userProfileData {
    background: white;
}
.profileContainer {
    position: relative;
    height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5% 5%;
    overflow: scroll;
    @media (max-width: $screen-mobile) {
        padding: 5% 5% 15% 5%;
    }
}
.profilePicSection {
    display: flex;
    justify-content: center;
}
.profilePicInfo {
    width: 100%;
}
.profilePicSubsection {
    display: flex;
    flex-direction: column;
    align-items: center;
    .profileLocation, .profileAge {
        font-size: 13px;
    }
    .communityContainer {
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 17px;
        @media (max-width: $screen-mobile)  {
            font-size: 14px;
        }
        .community {
            font-size: 13px;
            background-color: $brand-skill-tag;
            color: $brand-secondary;
            padding: 5px 10px;
            border-radius: 10px;
        }
    }
}
.largeProfileCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    border: 1px solid #29313c;
    border-radius: 50%;
    width: 140px;
    min-width: 140px;
    height: 140px;
    min-height: 140px;
    position: relative;
    float: left;
    background-size: cover;
    .cameraIconButton {
        position: absolute;
        bottom: 5px;
        right: -15px;
        background-color: #ffffff;
        border: 2px solid #d0d0d0; /* Add border here */
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        z-index: 1; /* Ensure the button is on top */
        img {
            width: 20px;
            height: 20px;
            z-index: 1;
        }
    }
}
@media (max-width: 425px) {
    .largeProfileCircle {
        width: 150px;
        height: 150px;
        min-width: 100px;
        min-height: 100px;
    }
    .largeProfileCircle .cameraIconButton {
        width: 35px; /* Adjust for smaller screens */
        height: 35px; /* Adjust for smaller screens */
    }
    .largeProfileCircle .cameraIconButton img {
        width: 20px; /* Adjust icon size for smaller screens */
        height: 20px; /* Adjust icon size for smaller screens */
    }
}
.profilePictureWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.uploadButton {
    color: gray;
    padding: 10px 20px;
    border-radius: 10px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
    display: inline-block;
    text-align: center;
    transition: background-color 0.2s, border-color 0.2s;;
    line-height: 1.42857;
    font-weight: 300;
    font-size: 13.35px;
    width: max-content;
    &:hover {
        color: $brand-primary;
    }
}
.profileObjective {
    font-style: italic;
    text-align: center;
}
.profileUpperSection {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    margin-bottom: 30px;
    align-items: center;
    justify-content: center;
}
.profileDetails  {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    margin-bottom: 30px;
    .tabs {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        .circleSeparator {
            align-items: center;
            justify-items: center;
            border: solid 1px $brand-primary;
            background: $brand-primary;
            border-radius: 100%;
            width: 10px;
            height: 10px;
        }
        .tab {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            cursor: pointer;
            font-weight: 400;
            &.active {
                color: $brand-primary;
                text-decoration: underline;
                font-weight: 700;
            }
        }
    }
    .profileSection {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}

.profile-pic-subsection {
    display: flex;
    flex-direction: column;
    align-items: center;
    .profile-location, .profile-age, .profile_member_ts {
        font-size: 13px;
    }
}

.profile-objective {
    font-style: italic;
    text-align: center;
}

.editable-section {
    display: flex;
    flex-direction: column;
    //gap: 5px;
    margin-bottom: 30px;
}
.fields {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.field-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin: 5px;
    &.fields-section {
        border: solid 1px #EBEDED;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        margin-bottom: 0px;
    }
    &.display-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .profile-title, .profile-goal  {
        width: 221px;
    }
    .profile-location {
        width: 70%;
    }
    
}
input {
    border: 1px solid #edecec;
    //border-radius: 3px;
    background: none;
    margin: 0px 5px 0px 0px;
    color: gray;
    padding-left: 10px;
    padding-right: 10px;
    //border-radius: 0.75rem;
}

.number-count {
    border: solid lightgrey 1px;
    background-color: lightgrey;
    border-radius: 4px;
    padding: 0px 5px;
    height: 25px;
    align-items: center;
    display: flex;
    &.active {
        background-color: #ECFEF4;
        border: solid #d4e5dc 1px;
    }
}
  
/* Remove margin from the last card */
.profile-details  {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    margin-bottom: 30px;
    .tabs {
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        .circleSeparator {
            align-items: center;
            justify-items: center;
            border: solid 1px $brand-primary;
            background: $brand-primary;
            border-radius: 100%;
            width: 10px;
            height: 10px;
        }
        .tab {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            cursor: pointer;
            font-weight: 400;
            &.active {
                color: $brand-primary;
                text-decoration: underline;
                font-weight: 700;
            }
        }
    }
    .profileSection {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
}

.portfolioSection {
    display: flex;
    flex-direction: row;
    //gap: 20px;
    .portfolioVertical {
        width: 100%;
        overflow-x: hidden;
        flex-direction: column;
        .portfolioEditor {
            display: none;
            opacity: 0;
            position: absolute;
            background: white;
            border-color: black;
            border-radius: 20px;
            width: 400px;
            height: fit-content;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            z-index: 1;
            @media (max-width: 550px) {
                left: 5%;
                max-width: 90%;
            }

            &.visible {
                display: block;
                opacity: 1;
                transform: translateY(0);
            }
            .portfolioEditorInner {
                display:flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                padding: 10px 20px 10px 20px; 
                .editPortfolio {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    .portfolioUrlInput {
                        margin-bottom: 0px;
                    }
                    .updatePortfolioItem {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        &:hover {
                            color: $brand-primary;
                        }
                    }
                }
            }
        }
    }
    .urlErrorMessage {
        margin-top: -12px;
        font-size: 13px;
        color: red;
    }
    .portfolioLink {
        position: relative;
        background-size: cover;
        background-position: center;
        display: flex;
        min-width: 45px;
        min-height: 45px;
        max-height: 45px;
        max-width: 45px;
        border-radius: 100%;
        cursor: pointer;
        border: 1px solid #ebeded00;
        background-repeat: no-repeat;
        transition: border-color 0.2s;
        &:hover{
            border: 1px solid $brand-secondary;
        }
        .deleteButton {
            position: absolute;
            bottom:-4px;
            right: -8px;
            width: 20px;
            height: 20px;
            background-image: url('../../icons/delete-icon.png');
            background-size: cover;
            border: 1px solid $brand-background-gray;
            transition: border-color 0.2s;
            &:hover{
                border: 1px solid $brand-secondary;
            }
            border-radius: 100%;
        }
        &.selected {
            border: 1px solid $brand-secondary;
        }
    }
}

.portfolioLinksContainer {
    background-color: $brand-background-gray;
    border-radius: 20px;
    height: fit-content;
    padding: 10px 0px;
    width: -webkit-fill-available;
    margin-left: 10px;
    .portfolioLinksInnerContainer {
        padding: 2px 0px;
        height: 100%;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: flex-start;
        margin-left: 4%;
        margin-right: 4%;
        align-items: center;
        overflow-x: auto;
        overflow-y: clip;
    }
}
.numberCount {
    border: solid lightgrey 1px;
    background-color: lightgrey;
    border-radius: 4px;
    padding: 0px 5px;
    height: 25px;
    align-items: center;
    display: flex;
    &.active {
        background-color: #ECFEF4;
        border: solid #d4e5dc 1px;
    }
}

.editableSection {
    display: flex;
    flex-direction: column;
    //gap: 5px;
    margin-bottom: 30px;
}
.fields {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.fieldRow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin: 5px;
    &.fields-section {
        border: solid 1px $brand-background-gray;
        border-radius: 10px;
        padding: 10px;
        display: flex;
        margin-bottom: 0px;
    }
    &.display-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .profileTitle, .profileGoal  {
        width: 221px;
    }
    .profileLocation {
        width: 70%;
    }
    .profileCommunity {
        width: 50%;
    }
    
}
input {
    border: 1px solid #edecec;
    //border-radius: 3px;
    background: none;
    margin: 0px 5px 0px 0px;
    color: gray;
    padding-left: 10px;
    padding-right: 10px;
    //border-radius: 0.75rem;
}

.smallDeleteButton {
    cursor: pointer;
    position: absolute;
    bottom:-10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-image: url('../../icons/delete-icon.png');
    background-size: cover;
    border: 1px solid $brand-background-gray;
    transition: border-color 0.2s;
    &:hover{
        border: 1px solid $brand-secondary;
    }
    border-radius: 100%;
}

/* Typically Used for textarea field with char counter */
.smallDeleteButtonField {
    cursor: pointer;
    position: absolute;
    bottom:-27px;
    right: -27px;
    width: 20px;
    height: 20px;
    background-image: url('../../icons/delete-icon.png');
    background-size: cover;
    border: 1px solid $brand-background-gray;
    transition: border-color 0.2s;
    &:hover{
        border: 1px solid $brand-secondary;
    }
    border-radius: 100%;
}

/* Typically Used for simple input field */
.smallDeleteButtonInput {
    cursor: pointer;
    width: 23px;
    height: 20px;
    background-image: url('../../icons/delete-icon.png');
    background-size: cover;
    border: 1px solid $brand-background-gray;
    transition: border-color 0.2s;
    &:hover{
        border: 1px solid $brand-secondary;
    }
    border-radius: 100%;
}
.plusIcon {
    background-image: url('../../icons/plus-icon.png');
    background-size: cover;
    background-position: center;
    min-width: 45px;
    min-height: 45px;
    max-height: 45px;
    max-width: 45px;
    border-radius: 100%;
    cursor: pointer;
    border: 1px solid #ebeded00;
    background-repeat: no-repeat;
    transition: border-color 0.2s;
    &:hover{
        border: 1px solid $brand-secondary;
    }
}
.profilePicSubsectionButtonOptions {
    margin-top: 20px;
    margin-bottom: 20px;
}
.profilePicSubsectionButton {
    display: flex;
    justify-content: center;
    .actionButtons {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}

.progressBarContainer {
    // position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px; /* Adjust height as needed */
    background-color: #e0e0e0; /* Background color for the progress bar container */
    border-radius: 3px; /* Optional: rounded corners for the progress bar container */
    overflow: hidden; /* Ensures progress bar does not overflow the container */
}

.progressBar {
    height: 100%;
    background-color: #4caf50; /* Progress bar color */
    transition: width 1s ease; /* Smooth transition for width change */
}

.grayVerticalContainer {
    margin-top: 10px;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: rgb(234, 237, 237);
    border-radius: 20px;
    position: relative;
}

// .gray-vertical-container::after {
//     content: '';
//     position: absolute;
//     top: 50%;
//     right: 10px; /* Adjust as needed */
//     transform: translateY(-50%);
//     border: solid gray;
//     border-width: 0 3px 3px 0;
//     display: inline-block;
//     padding: 10px;
//     transform: translateY(-50%) rotate(-45deg);
//     -webkit-transform: translateY(-50%) rotate(-45deg);
//     @media (max-width: 430px) {
//         right: 5px; /* Adjust for smaller screens */
//     }
// }
  
.personalProjectsContainer {
    width: 100%;
    .grayContainer {
        width: 100% !important;
    }
    //align-items: center;
    @media (max-width: 1120px) {
        padding-left: 0px;
        margin-top: 0px;
    }
    .personalProjects {
        max-height: 720px;
        display: flex;
        overflow-y: scroll;
        flex-direction: column;
        gap: 20px;
        margin-bottom: -10px;
        //overflow-y: scroll;
        @media (max-width: 430px) {
            align-items: center;
            //margin-bottom: 0px;
        }
        ::-webkit-scrollbar {
            width: 5px;
        }
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        ::-webkit-scrollbar-thumb {
            background: #888;
        }
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
        .project-card {
            margin-bottom: 20px;
        }
    }
}
.projectsNotFoundMessage {
    text-align: center;
    background: #fff;
    margin: 0 auto;
    padding: 30px;
    border-radius: 60px;
    --tw-shadow: 0px 4px 15px rgba(3, 24, 52, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 4px 15px var(--tw-shadow-color), 0px 2px 4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.cardsFeed {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: 70px;
    justify-content: center;
    position: relative;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
    &.horizontalScrolling {
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: nowrap;
      scroll-behavior: smooth;
    }
  }
.profile-label {
    border: 2px solid $brand-primary;
    display: inline-block;
    padding: 1px 11px;
    border-radius: 5px;
    color: $brand-primary;
}

.profile-description-card-height {
    background-color: white;
    width: 100%;
    min-width: 320px;
    min-height: 320px;
    max-height: 370px;
    border-radius: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    @media (max-width: 430px) {
        width: 100%;
        min-width: 300px;
    }
}
.skillsExpertiseSubsection, .interestsSubsection {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    .skillDropdown, .expertiseDropdown {
        margin-top: 10px;
        width: 100%;
    }
    .skillsContainer, .expertiseContainer, .interestsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        h3 {
            color: rgb(174, 174, 174);
            font-size: medium;
        }
    }
}
.tag {
    width: fit-content;
    padding: 0px 10px;
    border-radius: 5px;
    transition: border-color 0.2s;
    position: relative;
    &.selected {
        border: 1px solid $brand-secondary;
    }
    &.skill {
        border: 1px solid #E7EEFD;
        background: #E7EEFD;
        color: #17418B;
        transition: border-color 0.2s;
        cursor: default;
        &:hover{
            border: 1px solid #17418B;
        }
    }
    &.expertise {
        border: 1px solid #ECFEF4;
        background: #ECFEF4;
        color: $brand-secondary;
        transition: border-color 0.2s;
        cursor: default;
        &:hover{
            border: 1px solid $brand-secondary;
        }
    }
    &.interest {
        // border: 1px solid #B6D9D9;
        // background: #B6D9D9;
        border: 1px solid #ECFEF4;
        background: #ECFEF4;
        color: $brand-secondary;
        transition: border-color 0.2s;
        cursor: default;
        &:hover{
            border: 1px solid $brand-secondary;
        }
    }
}
.communities {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.interactive-profile-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 5px;
}

.flag-button {    
    position: absolute;
    align-items: center;
}

.small-icon {
    width: 20px;  // Reduced width to make the image smaller
    height: 20px; // Reduced height to make the image smaller
}

.modal-backdrop.show {
    opacity: 0.5;
}
.modal-backdrop.fade {
    opacity: 0;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}
.modal.show .modal-dialog {
    transform: none;
}
.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -100px);
}
@media (min-width: 576px) {
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
}
.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
    justify-content: center;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}
.modal-dialog {
    max-width: 1200px !important;
    margin: auto !important;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    font-weight: 500;
}
.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    background-color: rgb(41, 49, 60);
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    color: white;
    font-size: 15pt;
    font-weight: 400;
}
.close {
    font-size: 30px;
    float: right;
    line-height: 1;
    color: white;
    filter: alpha(opacity = 20);
    opacity: 0.2;
    border: none;
    background-color: rgba(52, 52, 52, 0);
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 2rem 3.5rem 2rem 3.5rem;
}
.modal-body-custom {
    max-height: 800px;
    overflow-y: auto;
    padding: 0px !important;
    height: 100%;
}
.modal-buttons {
    max-height: 40px;
    min-width: 87px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.modal-buttons .btn-secondary {
    border: 0px;
    background-color: #a1a1a1;
    color: white;
    transition: background-color 0.2s, border-color 0.2s;
}
.btn-primary {
    transition: background-color 0.2s, border-color 0.2s;
    background: #508484;
    color: #fff;
    border: 2px solid #508484;
}
.modal-buttons.center {
    justify-content: center;

}
.btn {
    text-decoration: none;
    font-weight: 300;
    border-radius: 10px;
    padding: 8px 20px;
    font-family: "sofia-pro", sans-serif;
    text-align: center;
    border: 1px solid transparent;
    line-height: 1.42857;
    transition: background-color 0.2s;
}
.message-modal.basic-message {
    text-align: center;
}
.modal-inner {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 2rem 3.5rem 2rem 3.5rem;
}
.modal-inner-dialog {
    display: flex;
    padding-bottom: 2rem;
}
@media (max-width: 576px) {
    .modal-inner {
        width: 100%;
        padding: 1.75rem;
    }

    .modal-header, .modal-body {
        padding: 1rem;
    }

    .modal-title {
        font-size: 1rem;
    }

    .modal-content {
        width: auto;
    }

    .btn {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
    }
}
.inputContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: 220px;
}

.video-container {
    display: flex;
    justify-content: center;
    @media (max-width: 1023px) {
        max-height: unset;
        height: 85vh;
    }
    margin: 0px auto;
    border-radius: 20px;
    overflow: hidden;
}

.video-overlay {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    font-size: 1.5em; /* Larger font for better visibility */
}

.profileEditContainer {
    padding-top: 5vh;
}

.subMenuSection {
    margin-top: 20px;
    .subMenu {
        cursor: pointer;
        position: relative;
        width: 30px;
        height: 10px;
        background-image: radial-gradient(circle, #b7b7b7 4px, transparent 4px);
        background-size: 33.33% 100%;
    }
}

.flagButton {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 5px;
}

.modalInner {
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.modalButtons {
    gap: 10px;
    display: flex;
    justify-content: center;
}

.notification {
    position: fixed; /* Fixed positioning to keep it in view */
    top: 80px; /* Position it from the top */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Offset by 50% of its width for perfect centering */
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    color: white; /* White text */
    padding: 10px 20px; /* Padding around the text */
    border-radius: 5px; /* Rounded corners */
    z-index: 1000; /* Make sure it's above other content */
    transition: opacity 0.5s ease; /* Smooth fade-in and fade-out */
  }

.input-container .small-delete-button {
    position: absolute;
    cursor: pointer;
}
