// Brand colors
$brand-primary: #508484 !default; // #337ab7
$brand-secondary: #2E4C4D !default;
$brand-tertiary: #99F6E0 !default;
$brand-background-gray: #EBEDED !default;
$brand-dark-gray: #b7b7b7 !default;
$brand-gray: rgb(234, 237, 237) !default;
$brand-skill-tag: #E7EEFD !default;
$brand-expertise-tag: #ECFEF4 !default;

// Figma colors
$untitledui-text-tertiary: #475467;
$untitledui-text-quarternary: #667085;
$untitledui-text-quarternary-hover: #344054;
$untitledui-text-primary: #101828;
$untitledui-text-placeholder: #667085;
$untitledui-text-placeholder-subtle: #D0D5DD;

// Standard screen sizes
$screen-mobile: 600px !default;
$screen-sm: 960px !default;

// Animation
@-webkit-keyframes fadeInDownMenu {
	from {
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translate3d(0, -10px, 0);
	transform: translate3d(0, -10px, 0);
	}
	
	to {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	transform: none;
	}
}
@keyframes fadeInDownMenu {
	from {
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translate3d(0, -10px, 0);
	transform: translate3d(0, -10px, 0);
	}
	
	to {
	visibility: visible;
	opacity: 1;
	-webkit-transform: none;
	transform: none;
	}
}