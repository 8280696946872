@import 'app/styling/variables.scss';
*:not(svg) {
	box-sizing: border-box;
}
.gray-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: $brand-gray;
    border-radius: 20px;
    width: 90%;
    padding: 0px 20px 2em 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    @media (max-width: 875px) {
        padding: 0px 5px 2em 5px;
    }
    &.full-width {
        width: 100%;
    }
}

.header-description-card {
    background-color: white;
    width: 100%;
    min-width: 320px;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border: 2px solid #EBEDED;
    @media (max-width: 430px) {
        width: 100%;
        min-width: 300px;
    }
    h3 {
        margin-top: 5px;
        margin-bottom: 10px;
    }
    textarea {
        width: 100%;
        border: 0px;
        color: gray;
    }
}

.textAreaWrapper {
	position: relative;
	min-height: 70px;
}

.char-counter-textarea {
    position: absolute;
    bottom: -12px;
    right: 0px;
    font-size: 0.75rem;
    color: #bdbdbd;
}

.not-found-message {
    text-align: center;
    background: #fff;
    margin: 0 auto;
    padding: 30px;
    border-radius: 60px;
    --tw-shadow: 0px 4px 15px rgba(3, 24, 52, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 4px 15px var(--tw-shadow-color), 0px 2px 4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

.divider {
	height: 1px;
	width: 100%;
	background-color: #edecec;
	margin-top: 5px;
	margin-bottom: 5px;
}

.horizontal-divider {
    height: 1px;
    background-color: #E4E7EC;
}

.secondary {
    transition: background-color 0.2s, border-color 0.2s;
    background-color: #a1a1a1;
    border-color: #a1a1a1;
    color: white;
    &:hover {
        background-color: #878787;
        border-color: #878787;
        color: white;
    }
}

.small-icon {
    height: 20px;
    position: relative;
    width: 20px;
    @media (max-width: 843px) {
        height: 13px;
        width: 13px;
    }
}

.general-container {
    margin: 0 5%;
}