@import 'app/styling/variables.scss';
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none;
}
.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0, -100px);
}
.modal.fade:not(.show) .modal-dialog {
    transition: transform .3s ease-in;
    transform: translateY(-100px);
}
@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
    transition: none;
}
}.modal.show .modal-dialog {
    transform: none;
}
.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}
.modal-dialog-scrollable {
    height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}
.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
    justify-content: center;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 11px;
    outline: 0;
    font-weight: 500;
    @media (max-width: 500px) {
        width: 100%;
    }
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}
.modal-backdrop.fade {
    opacity: 0;
}
.modal-backdrop.show {
    opacity: .5;
}
.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    background-color: rgb(41, 49, 60);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.modal-header .btn-close {
    padding: .5rem .5rem;
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    color: white;
    font-size: 15pt;
    font-weight: 400;
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 2rem 3.5rem 2rem 3.5rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    @media (max-width: 428px) {
        padding: 0rem 1rem 2rem 1rem;
        margin-top: 20px;
    }
}
.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px);
}
.modal-footer>* {
    margin: .25rem;
}
@media (min-width:576px) {
    .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
}
.modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
}
.modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
}
.modal-sm {
    max-width: 300px;
}
}@media (min-width:992px) {
    .modal-lg, .modal-xl {
    max-width: 800px;
}
}@media (min-width:1200px) {
    .modal-xl {
    max-width: 1140px;
}
}.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}
.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}
.modal-fullscreen .modal-header {
    border-radius: 0;
}
.modal-fullscreen .modal-body {
    overflow-y: auto;
}
.modal-fullscreen .modal-footer {
    border-radius: 0;
}
@media (max-width:575.98px) {
    .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}
.modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}
.modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
}
.modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
}
.modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
}
}@media (max-width:767.98px) {
    .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}
.modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}
.modal-fullscreen-md-down .modal-header {
    border-radius: 0;
}
.modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
}
.modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
}
}@media (max-width:991.98px) {
    .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}
.modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}
.modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
}
.modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
}
.modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
}
}@media (max-width:1199.98px) {
    .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}
.modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}
.modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
}
.modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
}
.modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
}
}@media (max-width:1399.98px) {
    .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}
.modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}
.modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
}
.modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
}
.modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
}}
button {
    border-radius: 0;
}
button:focus:not(:focus-visible) {
    outline: 0;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    line-height: inherit;
}
button, select {
    text-transform: none;
}
[role=button] {
    cursor: pointer;
}
select {
    word-wrap: normal;
}
select:disabled {
    opacity: 1;
}
[list]::-webkit-calendar-picker-indicator {
    display: none;
}
[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.close{
    font-size: 30px;
    float: right;
    line-height: 1;
    color: white;
    filter: alpha(opacity=20);
    opacity: .2;
    border: none;
    background-color: rgba(52, 52, 52, 0.0);
}
.form-control {
    width: 100%;
    padding: .25rem .75rem;
    font-size: 1rem;
    font-weight: 300;
    color: hsl(0, 0%, 20%);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #edecec;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
@media (prefers-reduced-motion:reduce) {
    .form-control {
    transition: none;
}
}.form-control[type=file] {
    overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer;
}
.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
}
.form-control::-webkit-date-and-time-value {
    height: 1.5em;
}
.form-control::-moz-placeholder {
    color: hsl(0, 0%, 50%);
    opacity: 5;
}
.form-control::placeholder {
    color: hsl(0, 0%, 50%);
    opacity: 5;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}
.form-control::file-selector-button {
    padding: .375rem .75rem;
    margin: -.375rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end: .75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
Alert {
    margin-top: 5px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.success-animation {
    .modal-content{
        left: 0;
        right: 0;
    }
}

.form-control.is-validated {
    border-color: #dc3545;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    background-position: right calc(.375em + .1875rem) center;
    background-repeat: no-repeat;
}

.form-control.is-invalid {
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-clip: padding-box;
    border: 1px solid #dc3545;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-bottom: 0;
}

.invalid-feedback {
    width: 100%;
    font-size: .875em;
    color: #dc3545;
}

.modal .form-group {
    margin-bottom: 0;
}
    
.modal .form-control + .invalid-feedback {
    margin-top: 0;
}

.recaptcha-branding {
    font-size: 10px;
    margin-top: 10px;
    text-align: center;
}
.grecaptcha-badge { 
    visibility: hidden;
}

.form-check-label {
    margin-left: 5px;
    font-size: 13px;
}