@import 'app/styling/variables.scss';
//Mixins
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
/* ---------------Style Starts--------------- */
h1, h2, h3, h4, h5, h6, figure {
  color: #303133;
  font-family: "sofia-pro", sans-serif;
  font-weight: 400;
  line-height: 1.3; 
  font-size: 30px;}

.colorlib-intro .colorlib-heading {
  text-align: center;}
  
  .colorlib-intro .play {
    width: 100%;
    display: block;
    margin: 30px 0; }
    .colorlib-intro .play a {
      width: 100px;
      height: 100px;
      display: table;
      margin: 0 auto;
      text-align: center;
      background: #798eea;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%; }
      .colorlib-intro .play a .play-button {
        font-size: 20px;
        color: #fff;
        display: table-cell;
        vertical-align: middle; 
        padding-top: 35px}

    .colorlib-heading h2 {
        color: $brand-secondary;
        font-size: 45px;
        font-weight: 600;
        position: relative; }
        @media screen and (max-width: 768px) {
        .colorlib-heading h2 {
            font-size: 30px; } }
            .colorlib-heading h3 {
              font-family: "sofia-pro", sans-serif;
              font-size: 25px;
              font-weight: 200;
              color: gray;
            }
            @media screen and (max-width: 768px) {
              .colorlib-heading h3 {
                font-size: 15px; }
              }
.pulse {
  display: table;
  width: 100px;
  height: 100px;
  background: $brand-secondary;
  cursor: pointer;
  box-shadow: 0 0 0 rgba($brand-secondary, 0.4);
  animation: pulse 2s infinite;
  text-align: center;
  @include border-radius(50%);
  i{
    font-size: 30px !important;
    display: table-cell;
    vertical-align: middle;
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($brand-secondary, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 30px rgba($brand-secondary, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba($brand-secondary, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($brand-secondary, 0.4);
    box-shadow: 0 0 0 0 rgba($brand-secondary, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 30px rgba($brand-secondary, 0);
      box-shadow: 0 0 0 30px rgba($brand-secondary, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba($brand-secondary, 0);
      box-shadow: 0 0 0 0 rgba($brand-secondary, 0);
  }
}
/* ---------------Style Ends--------------- */

/* ---------------BootStrap Starts--------------- */
.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
    border-radius: 6px;
  }
  .container:before, .container:after {
    content: " ";
    display: table;
  }
  .container:after {
    clear: both;
  }
  @media (min-width: 768px) {
    .container {
      width: 750px;
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
  }
  
  .container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .container-fluid:before, .container-fluid:after {
    content: " ";
    display: table;
  }
  .container-fluid:after {
    clear: both;
  }

  .row {
  }
  .row:before, .row:after {
    content: " ";
    display: table;
  }
  .row:after {
    clear: both;
  }
.col-md-8, .col-md-12 {
    position: relative;
    min-height: 1px;
}
@media (min-width: 992px) {
  .col-md-12, .col-md-8{
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-8 {
    width: 66.66667%;
  }
  .col-md-offset-2 {
    margin-left: 16.66667%;
}
}
/* ---------------BootStrap Ends--------------- */

/* ---------------Animate Starts--------------- */
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}
/* ---------------Animate Ends--------------- */

/* ---------------Icomoon Starts--------------- */
// .icon-play3:before {
//   content: "\ea4c";
// }
// [class^="icon-"], [class*=" icon-"] {
//   /* use !important to prevent issues with browser extensions that change fonts */
//   font-family: 'icomoon' !important;
//   speak: none;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;

//   /* Better Font Rendering =========== */
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
/* ---------------Icomoon Ends--------------- */
.pulse {
  display: table;
  width: 100px;
  height: 100px;
  background: #798eea;
  cursor: pointer;
  -webkit-box-shadow: 0 0 0 rgba(121, 142, 234, 0.4);
  box-shadow: 0 0 0 rgba(121, 142, 234, 0.4);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%; }
  .pulse Icon {
    font-size: 30px !important;
    display: table-cell;
    vertical-align: middle; }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(121, 142, 234, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0); } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0.4);
    -webkit-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0.4);
    box-shadow: 0 0 0 0 rgba(121, 142, 234, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 30px rgba(121, 142, 234, 0);
    -webkit-box-shadow: 0 0 0 30px rgba(121, 142, 234, 0);
    box-shadow: 0 0 0 30px rgba(121, 142, 234, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0);
    -webkit-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0);
    box-shadow: 0 0 0 0 rgba(121, 142, 234, 0); } }