@import 'app/styling/variables.scss';
.or-divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #d2d2d2;
  margin: 15px 0;
  margin-bottom: 10px;
}

.or-divider::before,
.or-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #d2d2d2;
}

.or-divider::before {
  margin-right: 10px;
}

.or-divider::after {
  margin-left: 10px;
}

.google-button {
  font-family: Nunito !important;
  width: 100% !important;
  margin-top: 26px !important;
  border: 2px solid $brand-primary !important;
  border-radius: 15px !important;
  color: #303133 !important;
  box-shadow: none !important;
  height: 50px;
  &:hover {
    background-color: $brand-primary !important;
    color: white !important;
  }
  div {
      height: 38px !important;
      border-radius: 19px !important;
      background: #ffffff !important;
      margin-left: 10px !important;
  }
}

.password-input-group {
position: relative;
}

.password-toggle {
background: transparent;
border: none;
cursor: pointer;
padding: 0;
z-index: 1;
right: 20px;
position: absolute;
}

.contains-password-toggle {
display: flex;
position: relative;
align-items: center;
}

.custom-signup-form, .custom-signin-form{
  display: flex;
  flex-direction: column;
  gap: 10px;
}