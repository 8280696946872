@import 'app/styling/variables.scss';
.profileMenu {
    position: absolute; /* Position relative to its parent div */
    top: 180%; /* Place it directly below its parent div*/
    left: 0; /* Align it to the left of its parent div */
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: 20px;
    z-index: 1000;
    display: none; /* Initially hidden */
	width: fit-content;
    &.visible {
        display: block; /* Show the menu */
        opacity: 1;
        transform: translateY(0); /* Move to the final position */
    }
}

.profileMenu ul {
	user-select: none;
	list-style-type: none;
	padding: 0;
	margin: 0;
	li {
		display: block;
		a {
			color: #303133 !important;
			padding: 2px 0;
			line-height: 2;
			white-space: nowrap;
		}
		a:hover {
			color: $brand-primary !important;
		}	
	}
}
	
.fadeInDownMenu {
	-webkit-animation-name: fadeInDownMenu;
	animation-name: fadeInDownMenu;
}

.animatedFast {
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}