@import 'app/styling/variables.scss';
.mobileVideoContainer {
    display: flex;
    justify-content: center;
    position: relative; /* Required for absolute positioning of overlay */
    max-width: calc(76.25px + 56.25vh); /* Max width based on the height */
    aspect-ratio: 0.5625 / 1; /* Ensure proper aspect ratio */
    margin: 0 auto; /* Center the video container */
    border-radius: 20px;
    overflow: hidden;
    max-height: unset;
    height: 85vh; /* Maintain the height on smaller screens */
    border-radius: 0px;
    @media (max-width: $screen-mobile) {
        height: 100vh;
        width: 100%;
    }
}
.videoOverlay {
    color: white;
    position: absolute; /* Overlay on top of the video */
    bottom: 60px; /* Move it near the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Center the overlay */
    display: flex; /* Flex layout to align elements horizontally */
    justify-content: space-between; /* Space between items */
    z-index: 1; /* Ensure it is on top of the video */
    flex-direction: column;
    padding: 0px 20px;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    @media (max-width: $screen-mobile) {
        bottom: 100px;
    }
    .profileInfo {
        display: flex; /* Ensure content inside this div is also in a row */
        align-items: center; /* Align the icon and text vertically */
        gap: 15px; /* Add some space between the elements */
        .displayName {
            font-size: 1.5rem;
            @media (max-width: $screen-mobile) {
                font-size: 17px;
            }
            color: $brand-tertiary;
        }
        .locationSection {
            display: flex;
            flex-direction: row;
            gap: 5px;
            align-items: center;
            font-size: 14px;
            .location {
                width: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
                text-wrap: nowrap;
            }
        }
    }

    // @media (max-width: 375px) {
    //     bottom: 170px;
    //     left: 57%;
    // }
    @media (max-width: 430px) {
        width: 100%;
    }
}
.roleInfo {
    display: flex; /* Make the roles stay on the same line */
    flex-direction: column;
    gap: 5px;
    margin-top: 5px;
}
.expertise {
    width: fit-content;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 5px;
    .expertiseItem {
        padding: 0px 7px;
        border-radius: 10px;
        border: solid 1px $brand-expertise-tag;
        background-color: $brand-expertise-tag;
        color: $brand-secondary;
        width: fit-content;
    }
}
.communityContainer {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    .communityItem {
        padding: 0px 7px;
        border-radius: 10px;
        border: solid 1px $brand-skill-tag;
        background-color: $brand-skill-tag;
        color: #17418B;
        width: fit-content;
    }
}

.additionalInfo {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    color: white;
    font-weight: bold;
}