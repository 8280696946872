// Bootstrap begins
@import 'app/styling/variables.scss';
.btn {
    text-decoration: none;
    font-weight: 300;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 10px;
    padding: 10px 16px;
    font-family: "sofia-pro", sans-serif;
    text-align: center;
    border: 1px solid transparent;
    line-height: 1.42857;
    transition: background-color 0.2s;
}
.btn.btn-md {
    padding: 8px 20px !important; 
}
.btn.btn-lg {
    padding: 16px 36px !important; 
}
.btn:hover, .btn:active, .btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline: none !important; 
}

.btn-primary {
    transition: background-color 0.2s, border-color 0.2s;
    background: $brand-primary;
    color: #fff;
    border: 2px solid $brand-primary;
}

.btn-primary:hover {
    background: $brand-secondary;
    border: 2px solid $brand-secondary;
    color: #fff;
}

.btn-primary.btn-outline {
    background: transparent;
    color: $brand-primary;
    border: 2px solid $brand-primary;
}
.btn-primary.btn-outline:hover, .btn-primary.btn-outline:focus, .btn-primary.btn-outline:active {
    background: $brand-primary;
    color: #fff; 
}
.btn-custom{
    font-size: 16px;
}

.btn-secondary {
    transition: background-color 0.2s, border-color 0.2s;
    background: $brand-secondary;
    //background: transparent;
    color: white;
    border: 2px solid transparent;
}

.btn-secondary:hover {
    background: $brand-primary;
    border: 2px solid $brand-primary;
}

.form-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: fit-content;
    margin-top: 1em;
    .btn-secondary {
        border: 0px;
        background-color: #a1a1a1;
        color: white;
        transition: background-color 0.2s, border-color 0.2s;;
    }
    .btn-secondary:hover {
        border: 0px;
        background-color: #878787;
    }
}

.video-interaction-button {
    z-index: 2;
    width: 60px;         /* Set a fixed width */
    height: 60px;        /* Set a fixed height equal to width */
    background-color: rgba(0, 0, 0, 0);
    color: #107569;
    border: none;
    border-radius: 50%;  /* Make it a circle */
    cursor: pointer;
    display: flex;        /* Use flex to center text */
    align-items: center;  /* Center vertically */
    justify-content: center; /* Center horizontally */
    transition: background-color 0.3s ease;

    /* Desktop hover effect */
    &:hover {
      background-color: $brand-background-gray;
    }

    /* Mobile tap effect */
    &:active {
      background-color: $brand-background-gray;
    }
  }
  /* Remove hover effect on mobile using a media query */
  @media (hover: none) {
    button:hover {
      background-color: rgba(0, 0, 0, 0); /* No hover effect on mobile */
    }
  }