@import 'app/styling/variables.scss';
article {
    display: block;
    background: #fff;
    cursor: default;
    height: 655px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    min-height: 655px;
    min-width: 450px;
    --tw-shadow: 0px 4px 15px rgba(3, 24, 52, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 4px 15px var(--tw-shadow-color), 0px 2px 4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-radius: 20px; 
    .card-top-headers {
        min-height: 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 34px;
    }
    .card-main-header {
        min-height: 125px;
    }
    .card-goal-content {
        min-height: 103.5px;
    }
    .card-how-content {
        min-height: 187.5px;
        max-height: 187.5px;
        ul {
            padding-left: 15px;
            white-space: normal !important;
        }
    }
    #status {
        float: right;
        color: $brand-primary;
    }
    #category, #status{
        font-weight: 500;
        font-size: 17px;
    }
    #category {
        text-transform: uppercase;
    }
    h2 {
        margin-top: 0px;
        font-weight: 200;
        margin-bottom: 0px; 
        font-size: 30px;
        color: #fff; 
        line-height: 1.1;
    }
    h3 {
        font-weight: 200;
        margin-bottom: 15px; 
        font-size: 25px;
    }
    #date {
        color: #fff;
        font-size: 11px;
        margin-bottom: 5px;
    }
    #visibility {
        color: #fff;
        font-size: 14px;
        border-radius: 10px;
        padding: 0px 20px;
        margin-bottom: 5px;
    }
    #visibility.public {
        background-color: $brand-primary;
    }
    #visibility.draft {
        background-color: gray;
    }
}
    article .admin span {
        font-size: 12px;
        color: #303133; }
        article .author-img {
            float: left;
            position: absolute;
            top: 13px;
            left: 0;
            display: block;
            width: 64px;
            height: 64px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            border-radius: 50%; }
        article {
            .author {
                margin-bottom: -8px;
            }
            .author, .author-title, .recruiting-link {
                color: gray;
                font-size: 12px;
                text-decoration: none;
                display: block;
                margin-top: -5px;
                min-height: 24px;
            }
            .recruiting-link {
                border: 1px solid #5D9898;
                border-radius: 6px;
                padding: 0px 25px 0px 10px;
                font-weight: 400;
                color: #5D9898;
                margin-top: 0px;
                svg {
                    margin-left: 5px;
                    margin-bottom: -1px;
                }
            }
            .recruiting-link:hover {
                color: #5D9898;
            }
        }
    article .project-footer {
        margin-top: 1em;
        position:absolute;
        min-width: 390px;
        bottom: 15px;
    }
    article{
        .btn-primary {
            // margin-top: 26px;
            margin-top: 37px;
            float: right;
        }
        .btn-primary:disabled{
            background-color: gray;
            border-color: gray;
            pointer-events: none;
        }
    }
.author-data {
    float: left;
    margin-top: 15px;
    margin-left: 80px;
    width: 203px;
    // position: absolute;
}
/* ---------------Animate Starts Here---------------*/
@keyframes bounce-5 {
    0%   { transform:translateY(0); }
    10%  { transform:translateY(-4px);}
    15%  { transform:translateY(0); }
    17%  { transform:translateY(-1.5px); }
    25%  { transform:translateY(0); }
    64%  { transform:translateY(0); }
    100% { transform:translateY(0); }
}
/* ---------------Animate End Here---------------*/
.display-linebreak {
    white-space: pre-line;
}
.card-main-header {
    flex-direction: column;
    justify-content: space-between;
    display: flex;
    background-color: $brand-secondary;
    margin-top: 10px;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    height: 150px;
    h2 {
        flex-grow: 1;
        display: flex;
        align-items: center;
        min-height: 93px;
    }
    .header-bottom {
        display: flex;
        justify-content: space-between;
    }
}
.card-main-content {
    font-size: 14px;
    line-height: 1.8;
}
.project-card{
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: flex;
    min-height: 1px;
    width: 450px;
}
@media (max-width: 500px) {
    .project-card {
        padding-right: 0px;
        padding-left: 0px;
        position: relative;
      }
    article{
        h2 {
            font-size: 25px;
        }
        h3 {
            font-size: 20px;
        }
        .project-footer {
            margin-top: 1em;
            position: absolute;
            bottom: 20;
            min-width: 351px;
        }
    }
    .card-main-content {
        font-size: 12px;
    }
    article {
        height: 585px;
        width: 90%;
        min-width: 405px;
        margin-left: auto;
        margin-right: auto;
        .author, .author-title, .recruiting-link {
            font-size: 11px;
        }
        .author-img {
            float: left;
            position: absolute;
            top: 10px;
            display: block;
            width: 54px;
            height: 54px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            border-radius: 50%; 
        }
        .author-data {
            float: left;
            margin-top: 15px;
            margin-left: 65px;
        }
        Button{
            margin-top: 26px;
            margin-left: 20px;
        }
    }
}

.project-visibility {
    display: flex;
    align-items: center;
}

.center-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.project-action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: width 0.5s;
}

.applied-checkmark {
    visibility: hidden;
    opacity: 0;
    animation: fadeIn 0.75s forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

.recruiting-dropdown {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	padding: 3px;
	border-radius: 3px;
	z-index: 1;
	transform: translateY(-10px);
	opacity: 0;
	transition: transform 0.3s, opacity 0.3s;
	ul {
		justify-content: center;
		padding: 3px;
		margin: 0;
	  }
	li {
		list-style-type: none;
		margin: 0;
		padding: 0 10px; /* Adjust the padding value as needed */
		width: fit-content;
	}
}
  
.dropdown:hover .recruiting-dropdown {
	display: block;
	transform: translateY(0);
	opacity: 1;
	overflow: auto;
}

.arrow {
    position: absolute;
    top: 65px;
    right: 207px;
    transform: translateY(-50%);
    width: 11px;            /* Reduced the width */
    height: 25px;           /* Reduced the height */
    background-image: url('../../icons/downwards-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;   /* Adjusted this so the image will scale down to fit inside the element */
    background-position: center; /* This will center the arrow in the middle of the space */
    transition: transform 0.3s ease;   /* Add this line to smoothly animate the transform property */

    @media screen and (max-width: 500px) {
        right: 192px;
        top: 63px;
    } 

    &.up {
        transform: translateY(-50%) rotate(180deg);
    }
}

.bubble {
    display: inline-block;
    padding: 0px 10px;
    background-color: #e4e6e7;
    color: #aeb4b7;
    border-radius: 25px;
    margin: 5px;
    font-size: 13px;
    cursor: pointer;
    transition: transform 0.2s;
}

.bubble:hover {
    transform: scale(1.1);
}