@import 'app/styling/variables.scss';
.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    @media (max-width: 877px) {
      display: unset;
    }
    > div {
      padding: 20px 0px;
      border-radius: 8px;
      width: 80%;
      max-width: 1000px;
      background: white;
      top: 8%;
      position: fixed;
      overflow-y: scroll;
      bottom: 50px;
      @media (max-width: $screen-sm) {
        width: 100%;
        bottom: 0;
      }
    }
}

@keyframes animateInFromTop {
    0% {
      transform: translateY(-50px);
    }
    100% {
      transform: translateY(0);
    }
}

@keyframes animateInFromBottom {
    0% {
      transform: translateY(500px);
    }
    100% {
      transform: translateY(0);
    }
}
  
.portalAnimation {
    animation: animateInFromTop 0.3s ease-out forwards;
    @media (max-width: 877px) {
        animation: animateInFromBottom 0.3s ease-out forwards ;
        height: 100%;
    }
}
  
@keyframes animateOutToTop {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-50px);
    }
}

@keyframes animateOutDownwards {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(1000px);
    }
}
  
.closingAnimation {
    animation: animateOutToTop 0.2s ease-out forwards;
    @media (max-width: 877px) {
        animation: animateOutDownwards 0.3s ease-out forwards ;
        height: 100%;
    }
}
  
.closeButton {
  position: absolute; /* Position the button absolutely within the modal */
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 10; /* Ensure it stays above other modal content */
  @media (min-width: 878px) {
    top: 15px;
  }
}