.btn.with-arrow {
    position: relative;
    transition: 0.3s; 
    cursor: pointer;}
    .btn.with-arrow .explore-button {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        right: 0px;
        top: 50%;
        transition: 0.2s; }
    .btn.with-arrow:hover {
        padding-right: 50px; }
        .btn.with-arrow:hover .explore-button {
        color: #fff;
        right: 18px;
        visibility: visible;
        opacity: 1; }
/* ---------------Style Ends Here---------------*/

/* ---------------Bootstrap Begins Here---------------*/
.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .container:before, .container:after {
    content: " ";
    display: table;
  }
  .container:after {
    clear: both;
  }
  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      width: 95%;
    }
  }
@media (min-width: 992px) {
    .col-md-4, .col-md-8 {
      width: 33.33333%;
    }
    .col-md-4 {
        width: 33.33333%;
    }
    .col-md-8 {
        width: 66.66667%;
    }
    .col-md-offset-2 {
        margin-left: 16.66667%;
    }
}

.btn.with-arrow {
    position: relative;
    transition: 0.3s; }
.btn.with-arrow i {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: -8px;
    transition: 0.2s; }
.btn.with-arrow:hover {
    padding-right: 50px; }
    .btn.with-arrow:hover i {
    color: #fff;
    right: 18px;
    visibility: visible;
    opacity: 1; }
.btn-outline {
    background: none;
    border: 1px solid gray;
    font-size: 16px;
    transition: 0.3s; }
    .btn-outline:hover, .btn-outline:focus, .btn-outline:active {
        -webkit-box-shadow: none;
        box-shadow: none; }
/* ---------------Bootstrap Ends Here---------------*/

.major-subheading {
  font-size: 25pt;
}

.sample-project-card {
  padding: 7em 0;
  @media screen and (max-width: 768px) {
      padding: 0 0 7em 0;
  }
}