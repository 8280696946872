@import 'app/styling/variables.scss';

// //** Global textual link color.
$link-hover-decoration: underline !default;

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
// supported browsers that have box shadow capabilities now support it.

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin transition($transition) {
  transition:         $transition ease;
}

@mixin tab-focus() {
  // Default
  outline: thin dotted;
  // WebKit
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
} 

@keyframes slideDownMenu {
	from {
	  opacity: 0;
	  visibility: hidden;
	  transform: translate3d(0, -10px, 0);
	}
  
	to {
	  visibility: visible;
	  opacity: 1;
	  transform: none;
	}
  }
  
  .slideDownMenu {
	animation-name: slideDownMenu;
}
.dropdown-slidedown {
	padding-left: 30px;
}

// Animation
.animated-fast {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeInUpMenu {
  from {
    opacity: 0;
    visibility: hidden;
    /*-webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);*/
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUpMenu {
  from {
    opacity: 0;
    visibility: hidden;
    /*-webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);*/
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  to {
    visibility: visible;
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUpMenu {
  -webkit-animation-name: fadeInUpMenu;
  animation-name: fadeInUpMenu;
}

@-webkit-keyframes fadeInDownMenu {
	from {
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translate3d(0, -10px, 0);
	transform: translate3d(0, -10px, 0);
	}
	
	to {
	opacity: 1;
	visibility: visible;
	-webkit-transform: none;
	transform: none;
	}
}
@keyframes fadeInDownMenu {
	from {
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translate3d(0, -10px, 0);
	transform: translate3d(0, -10px, 0);
	}
	
	to {
	visibility: visible;
	opacity: 1;
	-webkit-transform: none;
	transform: none;
	}
}
	
.fadeInDownMenu {
	-webkit-animation-name: fadeInDownMenu;
	animation-name: fadeInDownMenu;
	display: block !important;
}

.colorlib-nav {
	margin: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;	
	z-index: 11;
	margin: 0 auto;
	@media screen and (max-width: $screen-sm ) {
		margin: 0;
	}
	.disappear-on-resize {
		@media screen and (max-width: $screen-sm ) {
			display: none !important;
		}
	}
	ul {
		padding: 0;
		margin: 0;
		li {
			margin: 0;
			list-style: none;
			display: inline;
			font-weight: 300;
			font-size: 15px;
			min-width: fit-content;
			-webkit-font-smoothing: antialiased;
			a {
				cursor: pointer;
				display: flex;
				gap: 5px;
				align-items: center;
				position: relative;
				color: rgba(255,255,255,.9);
				font-family: "sofia-pro", sans-serif;
				font-size: 17px;
				text-wrap: nowrap;
			}
			&.has-dropdown {
				position: relative;
				#dropdown {
					display: none;
					opacity: 0;
					transition: opacity 0.3s ease-in-out;
					width: max-content;
					-webkit-box-shadow: 0px 14px 33px -9px rgba(0,0,0,0.75);
					-moz-box-shadow: 0px 14px 33px -9px rgba(0,0,0,0.75);
					box-shadow: 0px 14px 33px -9px rgba(0,0,0,0.75);
					z-index: 1002;
					visibility: hidden;
					opacity: 0;
					position: absolute;
					top: 40px;
					left: 0;
					text-align: left;
					background: rgba(41, 49, 60, .95);
					padding: 20px;
					@include border-radius(4px);
					@include transition(.0s);
					&.organized {
						column-count: 4;
						column-rule: 1px solid #ddd;
						column-gap: 20px;
					}
					&:before {
						bottom: 100%;
						left: 40px;
						border: solid transparent;
						content: " ";
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
						border-bottom-color: rgba(41, 49, 60, 0.8);
						border-width: 8px;
						margin-left: -8px;
					}
					
					li {
						display: block;
						margin-bottom: 0px;
						break-inside: avoid;
						&:last-child {
							margin-bottom: 0;
						}
						a {
							padding: 2px 0;
							display: block;
							color: rgba(255, 255, 255, 0.9);
							line-height: 2.0;
							text-transform: none;
							font-size: 14px;
							letter-spacing: 0;
							&:hover {
								color: $brand-primary;
							}
						}
					}
				}
				&:hover {
					#dropdown {
						visibility: visible;
						opacity: 1;
						@include transition(.3s);
						display: block;
						animation-name: fadeInDownMenu;
						animation-duration: 0.3s;
						animation-timing-function: ease;
						animation-fill-mode: both;
					}
				}
			}
			&.btn-cta {
				a {
					color: white;
					background: rgba($brand-primary,1);
					padding: 10px 15px;
					@include border-radius(100px);
					@include transition(.3s);
					&:hover {
						-webkit-box-shadow: 0px 14px 20px -9px rgba(0,0,0,0.75);
						-moz-box-shadow: 0px 14px 20px -9px rgba(0,0,0,0.75);
						-ms-box-shadow: 0px 14px 20px -9px rgba(0,0,0,0.75);
						-o-box-shadow: 0px 14px 20px -9px rgba(0,0,0,0.75);
						box-shadow: 0px 14px 20px -9px rgba(0,0,0,0.75);
					}
				}
			}
			&.active {
				> a {
					color: rgba(255,255,255,.5);
					position: relative;

					&:after{
						opacity: 1;
						-webkit-transform: translate3d(0, 0, 0);
						transform: translate3d(0, 0, 0);
					}
				}
			}
		}
	}
}
.row {
    }
    .row:before, .row:after {
    content: " ";
    display: table;
    }
    .row:after {
    clear: both;
}

.col-md-4 {
    position: relative;
    min-height: 1px;
    padding-left: 5%;
    padding-right: 20px;
}

@media (min-width: 993px) {
    .col-md-4 {
    float: left;
    width: 49.33333%;
    }
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

#dropdown {
    position: relative;
}

a {
    transition: 0.5s; 
    color: $brand-primary;
    text-decoration: none;
    // Remove the gray background color from active links in IE 10.
    background-color: transparent;

    &:hover,
    &:focus {
      color: darken($brand-primary, 15%);
      text-decoration: $link-hover-decoration;
  }

    &:focus {
      @include tab-focus;
    } 
  }
    a:hover, a:active, a:focus {
      outline: none;
      text-decoration: none; }

// Burger starts here
.colorlib-nav-toggle {
	cursor: pointer;
	text-decoration: none;
	margin-right: 3%;}
	.colorlib-nav-toggle.active i::before, .colorlib-nav-toggle.active i::after {
		background: #303133; }
	.colorlib-nav-toggle:hover, .colorlib-nav-toggle:focus, .colorlib-nav-toggle:active {
		outline: none;
		border-bottom: none !important; }
	.colorlib-nav-toggle i {
		position: relative;
		display: inline-block;
		width: 30px;
		height: 3px;
		color: #252525;
		font: bold 14px/.4 Helvetica;
		text-transform: uppercase;
		text-indent: -55px;
		background: #252525;
		transition: all .2s ease-out; }
		.colorlib-nav-toggle i::before, .colorlib-nav-toggle i::after {
		content: '';
		width: 30px;
		height: 3px;
		background: #252525;
		position: absolute;
		left: 0;
		transition: all .2s ease-out; }
	.colorlib-nav-toggle.colorlib-nav-white > i {
		background: #fff; }
		.colorlib-nav-toggle.colorlib-nav-white > i::before, .colorlib-nav-toggle.colorlib-nav-white > i::after {
		background: #fff; }
	
	.colorlib-nav-toggle i::before {
	top: -7px; }
	
	.colorlib-nav-toggle i::after {
	bottom: -7px; }
	
	.colorlib-nav-toggle:hover i::before {
	top: -10px; }
	
	.colorlib-nav-toggle:hover i::after {
	bottom: -10px; }
	
	.colorlib-nav-toggle.active i {
	background: transparent; }
	
	.colorlib-nav-toggle.active i::before {
	top: 0;
	-webkit-transform: rotateZ(45deg);
	-moz-transform: rotateZ(45deg);
	-ms-transform: rotateZ(45deg);
	-o-transform: rotateZ(45deg);
	transform: rotateZ(45deg);
	background: #fff; }
	
	.colorlib-nav-toggle.active i::after {
	bottom: 0;
	-webkit-transform: rotateZ(-45deg);
	-moz-transform: rotateZ(-45deg);
	-ms-transform: rotateZ(-45deg);
	-o-transform: rotateZ(-45deg);
	transform: rotateZ(-45deg);
	background: #fff; }
	
	.colorlib-nav-toggle {
	position: absolute;
	right: 0px;
	z-index: 2001;
	border-bottom: none !important; }
	@media screen and (max-width: $screen-sm) {
		.colorlib-nav-toggle {
		display: block; } }
	@media screen and (min-width: $screen-sm) {
		.colorlib-nav-toggle {
		display: none; } }

.left-nav-buttons {
	font-Size: 20px;
	display: flex;
	align-items: center;
	ul {
		display: flex;
		padding-left: 40px;
	}
}

.right-nav-buttons {
	ul {
		margin-right: 18px;
		padding-right: 40px;	
	}
}

.main-logo {
	display: flex;
	gap: 10px;
	float: left;
	position: relative;
	font-size: 25pt;
	font-weight: 500;
	color: white;
	font-weight: normal;
	margin-bottom: 10px;
	cursor: pointer;
	.sub-tag {
		border: 1px solid #99F6E0;
    	font-size: 10pt;
		align-self: center;
		padding: 0px 10px;
		border-radius: 7px;
		color: #99F6E0;
	}
}
.top-menu {
    background-color: transparent;
    height: 65px;
    top: 0;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background-color 0.3s;
}
.profile-navbar-group {
	display: inline-flex;
	float: right;
	margin-right: 6%;
}
.profile-circle::before {
	content: "";
	height: 80%; /* adjust based on your needs */
	border-left: 1px solid gray; /* adjust color as needed */
	position: absolute;
	left: -15px; /* adjust based on your needs */
	top: 10%; /* this will vertically center the divider */
}
.profile-circle {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f8f9fa;
	border: 1px solid #29313c;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	cursor: pointer;
	position: relative;
	float: right;
	margin-left: 20px;
	background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}
  
.profile-menu {
	display: none;
	opacity: 0;
	position: absolute;
	top: calc(100% + 5px); // Position the menu below the profile icon with a small gap
	right: 0; // Align the menu with the right edge of the profile icon
	background-color: white;
	border-radius: 5px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	padding: 20px;
	margin-right: 5%;
	transition: transform 0.3s, opacity 0.3s ease-in-out;
	animation-name: fadeInDownMenu;
	animation-duration: 0.3s;
	animation-timing-function: ease;
	animation-fill-mode: both;
}
  
.profile-menu.visible {
	display: block;
	opacity: 1;
	transform: translateY(0); // Move to the final position
}

.profile-menu ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
	li {
		display: block;
		a {
			color: #303133 !important;
			padding: 2px 0;
			line-height: 2;
		}
		a:hover {
			color: $brand-primary !important;
		}	
	}
}

.divider {
	height: 1px;
	width: 100%;
	background-color: #edecec;
	margin-top: 5px;
	margin-bottom: 5px;
}
  
.clickable {
	cursor: pointer;
	transition: transform 0.3s ease-in-out;
}
  
.clickable:hover {
    transform: translateY(-10px);
}