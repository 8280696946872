.large-expandable-list {
    margin: 0 20% 0 20%;
    @media screen and (max-width: 768px) {
        margin: 0 5% 0 5%;
    }
}
.large-expandable-item {
    cursor: pointer;
    overflow: hidden;
    transition: max-height 0.3s ease; // Updated curve for collapsing
    max-height: calc(50px + 10px + 30px + 20px);
    padding: 20px 40px 30px 40px;
    margin-bottom: 30px;
  
    @media screen and (max-width: 768px) { // Updated breakpoint
      max-height: calc(50px + 20px + 15px);
      margin-bottom: 20px;
      padding: 20px 30px 30px 30px;
    }

    &.expanded {
      max-height: 1300px;
      transition: max-height 0.6s ease;
    }
  
    .large-expandable-header {
        font-size: 35px;
        color: #5D9898;
        position: relative;
        margin-bottom: 20px;

        @media screen and (max-width: 768px) {
            font-size: 25px;
        }
    
        .expandable-arrow {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            width: 30px;            /* Reduced the width */
            height: 25px;           /* Reduced the height */
            background-image: url('../../icons/downwards-arrow.png');
            background-repeat: no-repeat;
            background-size: contain;   /* Adjusted this so the image will scale down to fit inside the element */
            background-position: center; /* This will center the arrow in the middle of the space */
            transition: transform 0.3s ease;   /* Add this line to smoothly animate the transform property */

            @media screen and (max-width: 768px) {
                right: 0px;
                width: 20px;
                height: 15px;
            }

            &.up {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
  
    .large-expandable-desc {
        font-size: 20px;
        @media screen and (max-width: 768px) {
            font-size: 15px;
        }
    }
    svg {
        margin-right: 20px;
    }
}
