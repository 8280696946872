FormModal {
    z-index: 1000;
}

.modal-dialog {
    max-width: 1200px !important;
    @media (max-width: 993px) {
        width: 800px;
    }
    margin: auto !important;
}  
//padding: 2rem 3.5rem 2rem 3.5rem;
.modal-body-custom {
    max-height: 800px;
    overflow-y: auto;
    padding: 0px !important;
    height: 100%;
}

.message-modal {
    text-align: left;
    white-space: pre-line;
    &.basic-message {
        text-align: center;
    }
}

.project-card-modal {
    .modal-content {
        @media (max-width: 876px) {
            width: 90%;
        }
        @media (max-width: 575px) {
            width: 99%;
        }
    }
}

.form-like-button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    position: relative;
    width: 100%;
    border: 1px solid #dadce0;
    border-radius: 4px;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    cursor: pointer;
    transition: background-color .218s, border-color .218s;
    justify-content: space-between;
    height: 40px;
    padding: 0 12px;
    &:hover {
        background-color: #f8fafe;
        border-color: #cbdffc;
    }
    span {
        font-weight: 500;
        font-size: 14px;
        width: 100%;
        color: #3c4043;
        letter-spacing: 0.25px;
    }
    .icon {
        height: 20px;
        margin-right: 8px;
        min-width: 20px;
        width: 20px;
        background-size: contain;
        background-repeat: no-repeat;
        &.linkedin {
            background-image:url('../../icons/linkedin-icon.png');
        }
        &.generic-email {
            height: 15px;
            background-image:url('../../icons/generic-email.png');
        }
    }

}