@import 'app/styling/variables.scss';
$grid-gutter-width: 40px !default; 

// Alignment
.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-nowrap         { white-space: nowrap; }

@mixin clearfix() {
    &:before,
    &:after {
      content: " "; // 1
      display: table; // 2
    }
    &:after {
      clear: both;
    }
}

// Creates a wrapper for a series of columns

.animate-box {
	.js & {
		opacity: 0;
	}
}

body {
  font-family: "Sofia-pro", sans-serif;
  font-weight: 300;
  font-size: 17px;
  line-height: 2;
  color: gray;
  background: #FFFFFF; 
}

h1 {
    font-size: 2em; /* 32px if the body font-size is 16px */
    color: $untitledui-text-primary;
}

h2 {
    font-size: 1.5em; /* 24px if the body font-size is 16px */
}

h3 {
    font-size: 1.25em; /* 20px if the body font-size is 16px */
}

@media (max-width: 600px) {
    body {
        font-size: 14px;
    }
    h1 {
        font-size: 1.75em; /* Adjust down to 24.5px */
    }
    h2 {
        font-size: 1.4em; /* Adjust down to 19.6px */
    }
    h3 {
        font-size: 1.2em; /* Adjust down to 16.8px */
    }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
      -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

#page {
	overflow-x: hidden;
}

.no-select {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}