@import 'app/styling/variables.scss';
$neutral-colorslightwhite-50: rgba(253, 253, 253, 1);
$state-colorsinfo: rgba(0, 99, 247, 1);
$state-colorssuccess-1: rgba(5, 166, 96, 1);
$neutral-colorsdarkgrey-50: rgba(234, 234, 235, 1);
$neutral-colorsdarkgrey-500: rgba(48, 49, 51, 1);
$neutral-colorsdarkgrey-800: rgba(26, 27, 28, 1);
$primary-colorsgrey-50: rgba(234, 237, 237, 1);
$neutral-colorsdarkgrey-400: rgba(89, 90, 92, 1);
$primitives-secondary-color-variations-orange-400: #F3985C;
$neutral-colorsdarkgrey-600: rgba(44, 45, 46, 1);
$primary-colorsgrey-600: rgba(42, 69, 70, 1);

.large-project-card {
    justify-content: center;
    display: flex;
}
.content-wrapper {
    overflow-y: scroll;

    @media (max-width: 428px) {
        width: 100%;
    }
}


.card-popup {
    background-color: #fdfdfd;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    position: relative;

    .card-popup-v {
        background-color: $neutral-colorslightwhite-50;
        display: flex;
        height: 580px;
        position: relative;
        width: 822px;
        flex-direction: column;
        overflow-y: scroll;
        @media (max-width: 877px) {
            overflow-y: scroll;
            gap: 25px;
            height: 100%;
        }
    }

    .top-section {
        align-items: center;
        display: inline-flex;
        gap: 15px;
        position: relative;
        width: 100%;
        justify-content: space-between;
        @media (max-width: 877px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: unset;
            top: unset;
            left: unset;
        }
    }

    .card-header-info {
        align-items: center;
        display: flex;
        gap: 16px;
        justify-content: center;
        position: relative;
        @media (max-width: 877px) {
            gap: 15px;
            width: fit-content;
        }
    }

    .img {
        height: 88px;
        position: relative;
        width: 88px;
        @media (max-width: 877px) {
            height: 75px;
            width: 75px;
        }
    }

    .project-headers {
        align-items: flex-start;
        display: flex;
        flex: 1;
        flex-direction: column;
        flex-grow: 1;
        gap: 5px;
        position: relative;
        @media (max-width: 877px) {
            gap: 5px;
        }
    }

    .major-tags {
        align-items: flex-start;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        position: relative;
        margin-top: 10px;
    }

    .blue-tag-wrapper {
        align-items: center;
        background-color: #e5eeff;
        border-radius: 6px;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        justify-content: center;
        padding: 4px 16px;
        position: relative;
    }

    .category-wrapper {
        color: $state-colorsinfo;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18.2px;
        margin-top: -1px;
        position: relative;
        white-space: nowrap;
        width: fit-content;
        @media (max-width: 877px) {
            font-size: 10px;
        }
    }

    .green-tag-wrapper {
        align-items: center;
        background-color: #e7fff3;
        border-radius: 6px;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        justify-content: center;
        padding: 4px 16px;
        position: relative;
    }

    .status-wrapper {
        color: $state-colorssuccess-1;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18.2px;
        margin-top: -1px;
        position: relative;
        white-space: nowrap;
        width: fit-content;
        @media (max-width: 877px) {
            font-size: 10px;
        }
    }

    #visibility {
        color: white;
        border-radius: 6px;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        justify-content: center;
        padding: 4px 16px;
        position: relative;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18.2px;
        white-space: nowrap;
        width: fit-content;
        @media (max-width: 877px) {
            font-size: 10px;
        }
    }
    #visibility.public {
        background-color: #86b9b9f0;
    }
    #visibility.draft {
        background-color: #b1b1b1;
    }

    .title-wrapper {
        color: $neutral-colorsdarkgrey-800;
        font-size: 32px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 41.6px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 877px) {
            font-size: 25px;
            white-space: unset;
            line-height: 33.6px;
        }
    }

    .sub-heading {
        align-items: center;
        display: flex;
        gap: 4px;
        height: 16px;
        position: relative;
    }

    .date {
        color: gray;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15.6px;
        margin-top: -1px;
        position: relative;
        @media (max-width: 877px) {
          font-size: 12px;
        }
    }

    .project-metadata {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        width: 280px;
        overflow: hidden;
        white-space: nowrap;
        border-left: 1px solid;
        border-color: #d5dddd;
        padding: 0px 15px;
        @media (max-width: 877px) {
        padding: 0px 10px;
        }
    }

    .metadata-wrapper {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex: 0 0 auto;
        gap: 4px;
        justify-content: center;
        position: relative;
        width: 100%;
    }

    .metadata-item-wrapper {
        color: $neutral-colorsdarkgrey-500;
        flex: 1;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 18.2px;
        position: relative;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (max-width: 877px) {
            font-size: 12px;
        }
    }

    .project-main-info-section {
        align-items: flex-start;
        display: inline-flex;
        flex-direction: column;
        gap: 16px;
        height: 321px;
        position: relative;
        margin-top: 24px;
        max-width: 350px;
        @media (max-width: 430px) {
            position: unset;
            top: unset;
            left: unset;
            height: unset;
            margin-top: unset;
        }
    }

    .description-wrapper {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: relative;
        width: 379px;
        @media (max-width: 877px) {
            width: fit-content;
            max-width: 100%;
        }
    }

    .main-info-header {
        color: $neutral-colorsdarkgrey-500;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 23.4px;
        position: relative;
        width: 379px;
        @media (max-width: 877px) {
            width: fit-content;
            max-width: 100%;
        }
    }

    .main-info-wrapper {
        align-items: flex-start;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 8px;
        position: relative;
        width: 379px;
        @media (max-width: 877px) {
            width: fit-content;
            max-width: 100%;
        }
    }

    .p {
        color: $neutral-colorsdarkgrey-500;
        font-size: 15px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 2;
        margin-bottom: -4px;
        position: relative;
        width: 379px;
        margin-top: 0px;
        @media (max-width: 877px) {
            width: fit-content;
            max-width: 100%;
        }
    }

    .recruiting-for-section {
        align-items: flex-start;
        display: inline-flex;
        flex-direction: column;
        gap: 8px;
        width: 379px;
        margin-top: -318px;
        margin-left: 435px;
        max-height: 435px;
        top: 128px;
        color: #757575;
        @media (max-width: 430px) {
            left: unset;
            //position: unset;
            top: unset;
            left: unset;
            margin-top: 0px;
            margin-left: 0px;
            display: contents;
        }
    }

    .role-description {
        margin-top: 0px;
        font-weight: 300;
        color: #757575;
        @media (max-width: 877px) {
            font-size: 16px;
        }
    }

    .roles-section {
        align-self: stretch;
        background-color: $primary-colorsgrey-50;
        border: 1px solid;
        border-color: #d5dddd;
        border-radius: 16px;
        height: 419px;
        overflow: hidden;
        padding: 8px;
        position: relative;
        width: 100%;
        padding-right: 4px;
    }

    .scroll-wrapper {
        height: 100%;
        overflow-y: scroll;
        padding-right: 5px;
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        gap: 8px;
    }
    
    .scroll-wrapper::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
    }

    .scroll-wrapper::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,.5);
        border-radius: 8px;
    }

    .role-card {
        align-items: flex-start;
        align-self: stretch;
        background-color: #fdfdfd;
        border-radius: 8px;
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        gap: 8px;
        overflow: hidden;
        padding: 16px;
        position: relative;
        width: 300px;
    }

    .roles-item {
        align-self: stretch;
        color: #505050;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20.8px;
        margin-top: -1px;
        position: relative;
    }

    .scroll {
        background-color: $primitives-secondary-color-variations-orange-400;
        border-radius: 16px;
        height: 107px;
        left: 374px;
        position: absolute;
        top: 14px;
        width: 5px;
    }

    // .footer-wrapper {
    //     align-items: flex-start;
    //     background-color: $neutral-colorsdarkgrey-50;
    //     border-radius: 16px;
    //     display: flex;
    //     flex-direction: column;
    //     gap: 8px;
    //     padding: 8px;
    //     position: absolute;
    //     top: 484px;
    //     width: 385px;
    //     margin-top: 24px;
    //     @media (max-width: 877px) {
    //         position: unset;
    //         top: unset;
    //         left: unset;
    //         width: unset;
    //         margin-top: unset;
    //     }
    // }

    

    .project-link {
        cursor: pointer;
        color: $brand-secondary;
        &:hover {
            color:$brand-primary;
        }
    }
}

.footer-wrapper {
    align-items: flex-start;
    background-color: $neutral-colorsdarkgrey-50;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    position: relative;
    //top: 484px;
    bottom: 0px;
    //width: 385px;
    margin-top: 24px;
    @media (max-width: 877px) {
        position: unset;
        top: unset;
        left: unset;
        width: unset;
        //margin-top: unset;
    }
    .footer {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex: 0 0 auto;
        gap: 16px;
        justify-content: space-between;
        position: relative;
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }

    .avatar {
        align-items: center;
        display: flex;
        gap: 16px;
        position: relative;
        width: 175px;
        @media (max-width: 877px) {
            gap: 2px;
            min-width: 175px;
        }

        .content {
            align-items: flex-start;
            display: inline-flex;
            flex: 0 0 auto;
            flex-direction: column;
            gap: 2px;
            position: relative;
            width: 150px;
            overflow: hidden;
            div {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
            }
        }
    }

    .name {
        color: $neutral-colorsdarkgrey-600;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21px;
        margin-top: -1px;
        position: relative;
        white-space: nowrap;
        width: fit-content;
        cursor: pointer;
        transition: color 0.2s;
        &:hover {
            color: $brand-primary;
        }
    }

    .manager-wrapper {
        align-items: center;
        display: inline-flex;
        flex: 0 0 auto;
        gap: 8px;
        position: relative;
        .title {
            color: $neutral-colorsdarkgrey-400;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 150%;
            margin-top: -1px;
            position: relative;
            padding-right: 5px;
        }
    }

    .primary-button {
        all: unset;
        align-items: center;
        background-color: $brand-primary;
        border-radius: 8px;
        box-sizing: border-box;
        display: flex;
        width: 120px;
        gap: 8px;
        justify-content: center;
        padding: 13px 30px;
        position: relative;
        cursor: pointer;
        user-select: none; /* Disables text selection */
        color: $neutral-colorslightwhite-50;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 24px;
        margin-top: -1px;
        position: relative;
        white-space: nowrap;
        width: fit-content;
        transition: background-color 0.2s, border-color 0.2s;
        &:hover {
            background-color: $brand-secondary;
        }
        &:disabled{
            background-color: gray;
            border-color: gray;
            pointer-events: none;
        }
        @media (max-width: 428px) {
            padding: 10px 15px;
        }
    }

    .avatar-image {
        height: 56px;
        min-width: 56px;
        object-fit: cover;
        position: relative;
        border-radius: 100%;
        // border: 1px solid #ffffff00;
        border: 2px solid $brand-primary;
        cursor: pointer;
        transition: border-color 0.2s;
        &:hover {
            border: 1px solid $brand-primary;;
        }
    }

    .alert-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 5px;
        .custom-alert {
            user-select: none;
            border-radius: 30px;
            background-color: #d1234cf0;
            width: fit-content;
            padding: 3px 15px 3px 15px;
        }
    }    
}

.small-icon {
    height: 20px;
    position: relative;
    width: 20px;
    @media (max-width: 843px) {
        height: 13px;
        width: 13px;
    }
}

.gray-vertical-container-small {
    width: 100%;
    gap: 30px;
    margin-top: 10px;
    overflow-y: scroll;
    //min-height: 370px;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgb(234, 237, 237);
    border-radius: 20px;
    position: relative; 
    @media (max-width: 430px) {
        //display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        min-height: 340px;
    }
}