@import 'app/styling/variables.scss';
.communityCards {
    display: flex;
    justify-content: center;
    gap: 20px;
    overflow-x: scroll;
    padding: 2% 0;
    @media (max-width: 875px) {
        flex-direction: row;
        justify-content: flex-start;
        padding-bottom: 10px;
    }
    .communityCard {
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            object-fit: contain;
            cursor: pointer;
            transition: color 0.2s;
            max-width: fit-content;
            max-height: 150px;
            border-radius: 20px;
            border: 3px solid #50848400;
            &:hover {
                cursor: pointer;
                border: 3px solid white;;
            }
            @media (max-width: 1168px) {
                max-height: 100px;
            }
        }
    }
    /* Scrollbar styling */
    &::-webkit-scrollbar {
        height: 5px; /* Adjust height for horizontal scrollbar */
    }

    &::-webkit-scrollbar-track {
        background: transparent; /* Transparent track when scrollbar is not visible */
    }

    &::-webkit-scrollbar-thumb {
        background: #888; /* Scrollbar thumb color */
        border-radius: 10px; /* Round the corners of the scrollbar thumb */
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555; /* Scrollbar thumb color on hover */
    }
}