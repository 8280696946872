@import 'app/styling/variables.scss';
.privacyPolicy {
    margin: 10px 10%;
    padding: 20px;
    height: 65vh;
    overflow-y: scroll;
    border: solid $brand-gray 1px;
    border-radius: 20px;
    @media (max-width: 425px) {
        margin: 10px 2%;
    }
}