@import 'app/styling/variables.scss';
.project-card-form-container {
    display: flex;
}

.project-card-form {
	padding-right: 1.5rem;
	padding-left: 1.5rem;
    width: 500px;
	padding-bottom: 2rem;
	padding-top: 2rem;
	padding-left: 3.5rem;
	@media (max-width: 1439px) { 
		width: 100%;
		padding-right: 0;
		padding-left: 0;
	}
}

.project-card-bottom {
	flex-grow: 1;
	flex-direction: column;
	display: flex;
	align-items: center;
	padding-bottom: 2rem;
	padding-top: 2rem;
	order: 2;
	width: 65%;
	background: rgb(41, 49, 60);

	.prompt {
		display: flex;
		flex-direction: column;
		align-items: center;
		div {
			width: fit-content;
			text-align: center;
			color: white;
			border-radius: 30px;
			background-color: $brand-primary;
			padding: 10px 20px;
			font-size: 15px;
			margin-bottom: 20px;
		}
	}

	.create-project-button-wrapper {
		margin-top: 27px;
		display: flex;
		justify-content: center;
		.btn {
			height: 50px;
			margin: 6px 5px;
		}
		.delete-btn {
			// margin-right: 30px;
			color: white; 
			background-color: #A61C3C;
			border-color: #A61C3C;
		}
		.delete-btn:hover {
			border-color: #861731;
			background-color: #861731;
		}
	}
	@media (max-width: 1439px) { 
		align-items: unset;
		width: 100% !important;
	}
	@media (max-width: 1439px) {
		width: 65%;
	}
	.content-wrapper {
		position: unset;
	}
}

@media (max-width: 1439px) { 

	.project-card-form-container {
		flex-direction: column;
		max-width: 100%;
	}

	.project-card-form {
		width: 100%;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
}

.item-enter {
	opacity: 0;
	transform: translateY(-10px);
}
  
.item-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 500ms, transform 500ms;
}
  
.item-exit {
	opacity: 1;
	transform: translateY(0);
}
  
.item-exit-active {
	opacity: 0;
	transform: translateY(-10px);
	transition: opacity 200ms, transform 200ms;
}
  
.roles-container {
	padding-bottom: 15px;
}

.role-filter {
	width: 20% !important;
}

.basic-multi-select {
	width: 100%;
}

.react-select-portal {
	z-index: 9999; /* Adjust this value if necessary to bring the dropdown in front of other elements */
}

.no-options-message {
	padding-left: 20px;
}

.select-no-options {
	cursor: default;
	padding: 8px;
  }
  
.select-no-options:hover {
	background-color: #E0EBFF;
}

.project-card-form {
    .role-input-container {
      display: flex;
      .btn-primary {
        margin-bottom: 15px;
      }
    }
  
    .role-input-form {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  
    .roles-list {
      margin-top: 10px;
      list-style-type: none;
      padding: 0;
  
      li {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
		@media (max-width:1439px) {
			flex-direction: column;
		}
      }
	  .btn:hover {
		background-color: #bebebe;
	  }
    }
}

.small-delete-button {
    cursor: pointer;
    position: absolute;
    bottom:-10px;
    right: -10px;
    width: 20px;
    height: 20px;
    background-image: url('../../../icons/delete-icon.png');
    background-size: cover;
    border: 1px solid #EBEDED;
    transition: border-color 0.2s;
    &:hover{
        border: 1px solid $brand-secondary;
    }
    border-radius: 100%;
}

.char-counter-input-box {
	position: absolute;
	bottom: 10px;  // adjust these values
	right: 0;
	font-size: 0.75rem;  // adjust this value
	color: #bdbdbd;  // adjust this value
}

.icon-wrapper {
	align-items: center;
    display: flex;
}

.form-icon {
	height: 30px;
	cursor: pointer;
}

.role-name-description {
	width: 100%;
}