@import 'app/styling/variables.scss';
.defaultFormFormat {
	background-color: white;
	padding: 2rem 3.5rem 2rem 3.5rem;
	width: 500px;
	display: flex;
	flex-direction: column;
	gap: 25px;
	.formButtons {
		max-height: 40px;
		min-width: 87px;
		display: flex;
		justify-content: center;
    gap: 60px;
		width: 100%;
		align-items: center;
	}
	@media (max-width: 500px) {
		width: -webkit-fill-available;
	}
}
.circularButton {
    color: white;
    width: 60px;         /* Set a fixed width */
    height: 60px;
    border: none;
    border-radius: 50%;  /* Make it a circle */
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;        /* Use flex to center text */
    align-items: center;  /* Center vertically */
    justify-content: center; /* Center horizontally */

    &.close {
      background-color: $untitledui-text-placeholder-subtle;
      /* Desktop hover effect */
      &:hover {
        background-color: $untitledui-text-placeholder;
      }
      
      /* Mobile tap effect */
      &:active {
        background-color: $untitledui-text-placeholder;
      }
      /* Remove hover effect on mobile using a media query */
      @media (hover: none) {
        button:hover {
          background-color: $untitledui-text-placeholder-subtle; /* No hover effect on mobile */
        }
      }
    }
    &.send {
      background-color: $brand-primary;
          /* Desktop hover effect */
      &:hover {
        background-color: $brand-secondary;
      }
    
      /* Mobile tap effect */
      &:active {
          background-color: $brand-secondary;
      }
      /* Remove hover effect on mobile using a media query */
      @media (hover: none) {
        button:hover {
          background-color: $brand-primary; /* No hover effect on mobile */
        }
      }
    }
}