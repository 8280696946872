@import 'app/styling/variables.scss';
// Project Card scss variables
$avatarsalec-whitten-color-background: rgba(171, 182, 119, 1);
$avatarsaliah-lane-color-background: rgba(215, 192, 221, 1);
$avatarsalisa-hester-color-background: rgba(212, 175, 189, 1);
$avatarsanaiah-whitten-color-background: rgba(204, 192, 221, 1);
$avatarsandi-lane-color-background: rgba(210, 199, 172, 1);
$avatarsanita-cruz-color-background: rgba(194, 199, 184, 1);
$avatarsava-wright-color-background: rgba(201, 186, 156, 1);
$avatarscandice-wu-color-background: rgba(162, 168, 205, 1);
$avatarsdemi-wilkinson-color-background: rgba(190, 168, 135, 1);
$avatarsdrew-cano-color-background: rgba(209, 223, 195, 1);
$avatarseduard-franze-color-background: rgba(219, 208, 189, 1);
$avatarseva-bond-color-background: rgba(219, 192, 221, 1);
$avatarseve-leroy-color-background: rgba(218, 185, 187, 1);
$avatarsjoshua-wilson-color-background: rgba(210, 219, 189, 1);
$avatarskari-rasmussen-color-background: rgba(169, 181, 141, 1);
$avatarskate-morrison-color-background: rgba(219, 192, 221, 1);
$avatarskatherine-moss-color-background: rgba(162, 168, 205, 1);
$avatarskelly-williams-color-background: rgba(216, 199, 182, 1);
$avatarskoray-okumus-color-background: rgba(212, 178, 175, 1);
$avatarslana-steiner-color-background: rgba(212, 181, 173, 1);
$avatarsloki-bright-color-background: rgba(185, 207, 208, 1);
$avatarslori-bryson-color-background: rgba(217, 185, 187, 1);
$avatarslucy-bond-color-background: rgba(219, 192, 221, 1);
$avatarslyle-kauffman-color-background: rgba(210, 177, 172, 1);
$avatarsmollie-hall-color-background: rgba(203, 159, 162, 1);
$avatarsnala-goins-color-background: rgba(192, 198, 221, 1);
$avatarsnatali-craig-color-background: rgba(209, 186, 169, 1);
$avatarsnoah-pierre-color-background: rgba(212, 175, 189, 1);
$avatarsolivia-rhye-color-background: rgba(199, 185, 218, 1);
$avatarsorlando-diggs-color-background: rgba(207, 195, 167, 1);
$avatarsphoenix-baker-color-background: rgba(170, 156, 117, 1);
$avatarsrene-wells-color-background: rgba(223, 204, 159, 1);
$avatarsrosalee-melvin-color-background: rgba(219, 202, 189, 1);
$avatarssophia-perez-color-background: rgba(182, 205, 216, 1);
$avatarszahir-mays-color-background: rgba(195, 199, 223, 1);
$display-font-size: 64px;
$display-font-style: normal;
$display-font-weight: 700;
$display-letter-spacing: 0px;
$display-line-height: 139.9999976158142%;
$heading-1-bold-font-size: 48px;
$heading-1-bold-font-style: normal;
$heading-1-bold-font-weight: 700;
$heading-1-bold-letter-spacing: 0px;
$heading-1-bold-line-height: 139.9999976158142%;
$heading-1-semibold-font-size: 48px;
$heading-1-semibold-font-style: normal;
$heading-1-semibold-font-weight: 600;
$heading-1-semibold-letter-spacing: 0px;
$heading-1-semibold-line-height: 139.9999976158142%;
$heading-2-bold-font-size: 40px;
$heading-2-bold-font-style: normal;
$heading-2-bold-font-weight: 700;
$heading-2-bold-letter-spacing: 0px;
$heading-2-bold-line-height: 139.9999976158142%;
$heading-2-semibold-font-size: 40px;
$heading-2-semibold-font-style: normal;
$heading-2-semibold-font-weight: 600;
$heading-2-semibold-letter-spacing: 0px;
$heading-2-semibold-line-height: 139.9999976158142%;
$heading-3-bold-font-size: 32px;
$heading-3-bold-font-style: normal;
$heading-3-bold-font-weight: 700;
$heading-3-bold-letter-spacing: 0px;
$heading-3-bold-line-height: 139.9999976158142%;
$heading-3-semibold-font-size: 32px;
$heading-3-semibold-font-style: normal;
$heading-3-semibold-font-weight: 600;
$heading-3-semibold-letter-spacing: 0px;
$heading-3-semibold-line-height: 139.9999976158142%;
$heading-4-bold-font-size: 24px;
$heading-4-bold-font-style: normal;
$heading-4-bold-font-weight: 700;
$heading-4-bold-letter-spacing: 0px;
$heading-4-bold-line-height: 139.9999976158142%;
$heading-4-medium-font-size: 24px;
$heading-4-medium-font-style: normal;
$heading-4-medium-font-weight: 500;
$heading-4-medium-letter-spacing: 0px;
$heading-4-medium-line-height: 139.9999976158142%;
$heading-4-semibold-font-size: 24px;
$heading-4-semibold-font-style: normal;
$heading-4-semibold-font-weight: 600;
$heading-4-semibold-letter-spacing: 0px;
$heading-4-semibold-line-height: 139.9999976158142%;
$neutral-colorsdarkgrey-100: rgba(191, 191, 192, 1);
$neutral-colorsdarkgrey-200: rgba(160, 160, 161, 1);
$neutral-colorsdarkgrey-300: rgba(116, 117, 118, 1);
$neutral-colorsdarkgrey-400: rgba(89, 90, 92, 1);
$neutral-colorsdarkgrey-50: rgba(234, 234, 235, 1);
$neutral-colorsdarkgrey-500: rgba(48, 49, 51, 1);
$neutral-colorsdarkgrey-600: rgba(44, 45, 46, 1);
$neutral-colorsdarkgrey-700: rgba(34, 35, 36, 1);
$neutral-colorsdarkgrey-800: rgba(26, 27, 28, 1);
$neutral-colorsdarkgrey-900: rgba(20, 21, 21, 1);
$neutral-colorslightwhite-100: rgba(247, 247, 247, 1);
$neutral-colorslightwhite-200: rgba(244, 244, 244, 1);
$neutral-colorslightwhite-300: rgba(238, 238, 238, 1);
$neutral-colorslightwhite-400: rgba(235, 235, 235, 1);
$neutral-colorslightwhite-50: rgba(253, 253, 253, 1);
$neutral-colorslightwhite-500: rgba(230, 230, 230, 1);
$neutral-colorslightwhite-600: rgba(209, 209, 209, 1);
$neutral-colorslightwhite-700: rgba(163, 163, 163, 1);
$neutral-colorslightwhite-800: rgba(127, 127, 127, 1);
$neutral-colorslightwhite-900: rgba(97, 97, 97, 1);
$paragraph-1-bold-font-size: 20px;
$paragraph-1-bold-font-style: normal;
$paragraph-1-bold-font-weight: 700;
$paragraph-1-bold-letter-spacing: 0px;
$paragraph-1-bold-line-height: 139.9999976158142%;
$paragraph-1-medium-font-size: 20px;
$paragraph-1-medium-font-style: normal;
$paragraph-1-medium-font-weight: 500;
$paragraph-1-medium-letter-spacing: 0px;
$paragraph-1-medium-line-height: 139.9999976158142%;
$paragraph-1-regular-font-size: 20px;
$paragraph-1-regular-font-style: normal;
$paragraph-1-regular-font-weight: 400;
$paragraph-1-regular-letter-spacing: 0px;
$paragraph-1-regular-line-height: 139.9999976158142%;
$paragraph-1-semibold-font-size: 20px;
$paragraph-1-semibold-font-style: normal;
$paragraph-1-semibold-font-weight: 600;
$paragraph-1-semibold-letter-spacing: 0px;
$paragraph-1-semibold-line-height: 139.9999976158142%;
$paragraph-2-bold-font-size: 16px;
$paragraph-2-bold-font-style: normal;
$paragraph-2-bold-font-weight: 700;
$paragraph-2-bold-letter-spacing: 0px;
$paragraph-2-bold-line-height: 139.9999976158142%;
$paragraph-2-medium-font-size: 16px;
$paragraph-2-medium-font-style: normal;
$paragraph-2-medium-font-weight: 500;
$paragraph-2-medium-letter-spacing: 0px;
$paragraph-2-medium-line-height: 139.9999976158142%;
$paragraph-2-regular-font-size: 16px;
$paragraph-2-regular-font-style: normal;
$paragraph-2-regular-font-weight: 400;
$paragraph-2-regular-letter-spacing: 0px;
$paragraph-2-regular-line-height: 139.9999976158142%;
$paragraph-2-semibold-font-size: 16px;
$paragraph-2-semibold-font-style: normal;
$paragraph-2-semibold-font-weight: 600;
$paragraph-2-semibold-letter-spacing: 0px;
$paragraph-2-semibold-line-height: 139.9999976158142%;
$paragraph-3-bold-font-size: 14px;
$paragraph-3-bold-font-style: normal;
$paragraph-3-bold-font-weight: 700;
$paragraph-3-bold-letter-spacing: 0px;
$paragraph-3-bold-line-height: 139.9999976158142%;
$paragraph-3-medium-font-size: 14px;
$paragraph-3-medium-font-style: normal;
$paragraph-3-medium-font-weight: 500;
$paragraph-3-medium-letter-spacing: 0px;
$paragraph-3-medium-line-height: 139.9999976158142%;
$paragraph-3-regular-font-size: 14px;
$paragraph-3-regular-font-style: normal;
$paragraph-3-regular-font-weight: 400;
$paragraph-3-regular-letter-spacing: 0px;
$paragraph-3-regular-line-height: 139.9999976158142%;
$paragraph-3-semibold-font-size: 14px;
$paragraph-3-semibold-font-style: normal;
$paragraph-3-semibold-font-weight: 600;
$paragraph-3-semibold-letter-spacing: 0px;
$paragraph-3-semibold-line-height: 139.9999976158142%;
$paragraph-4-bold-font-size: 12px;
$paragraph-4-bold-font-style: normal;
$paragraph-4-bold-font-weight: 700;
$paragraph-4-bold-letter-spacing: 0px;
$paragraph-4-bold-line-height: 139.9999976158142%;
$paragraph-4-medium-font-size: 12px;
$paragraph-4-medium-font-style: normal;
$paragraph-4-medium-font-weight: 500;
$paragraph-4-medium-letter-spacing: 0px;
$paragraph-4-medium-line-height: 139.9999976158142%;
$paragraph-4-regular-font-size: 12px;
$paragraph-4-regular-font-style: normal;
$paragraph-4-regular-font-weight: 400;
$paragraph-4-regular-letter-spacing: 0px;
$paragraph-4-regular-line-height: 139.9999976158142%;
$paragraph-4-semibold-font-size: 12px;
$paragraph-4-semibold-font-style: normal;
$paragraph-4-semibold-font-weight: 600;
$paragraph-4-semibold-letter-spacing: 0px;
$paragraph-4-semibold-line-height: 139.9999976158142%;
$primary-colorsgrey-100: rgba(190, 200, 200, 1);
$primary-colorsgrey-200: rgba(159, 173, 173, 1);
$primary-colorsgrey-300: rgba(115, 135, 136, 1);
$primary-colorsgrey-400: rgba(88, 112, 113, 1);
$primary-colorsgrey-50: rgba(234, 237, 237, 1);
$primary-colorsgrey-500: rgba(46, 76, 77, 1);
$primary-colorsgrey-600: rgba(42, 69, 70, 1);
$primary-colorsgrey-700: rgba(33, 54, 55, 1);
$primary-colorsgrey-800: rgba(25, 42, 42, 1);
$primary-colorsgrey-900: rgba(19, 32, 32, 1);
$state-colorserror: rgba(255, 59, 59, 1);
$state-colorserror-1: rgba(229, 53, 53, 1);
$state-colorserror-2: rgba(255, 92, 92, 1);
$state-colorserror-3: rgba(255, 128, 128, 1);
$state-colorsinfo: rgba(0, 99, 247, 1);
$state-colorsinfo-1: rgba(0, 79, 196, 1);
$state-colorsinfo-2: rgba(91, 141, 239, 1);
$state-colorsinfo-3: rgba(157, 191, 249, 1);
$state-colorssuccess: rgba(6, 194, 112, 1);
$state-colorssuccess-1: rgba(5, 166, 96, 1);
$state-colorssuccess-2: rgba(57, 217, 138, 1);
$state-colorssuccess-3: rgba(87, 235, 161, 1);
$state-colorswarning: rgba(255, 204, 0, 1);
$state-colorswarning-1: rgba(229, 184, 0, 1);
$state-colorswarning-2: rgba(253, 221, 72, 1);
$state-colorswarning-3: rgba(253, 237, 114, 1);

.small-card-wrapper {
  min-height: 360px;
  @media (max-width: 500px) {
    width: 100%;
    min-height: 330px;
  }
}

.semi-clickable {
  cursor: pointer;
  border: 1px solid #ffffff00;
  transition: border-color 0.2s;
  &:hover {
    border: 1px solid $brand-secondary;
  }
}


.small-card {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 349px;
  padding: 24px;
  position: relative;
  border-radius: 20px;
  max-width: 497px;
  // --tw-shadow: 0px 4px 15px rgba(3, 24, 52, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.05);
  // --tw-shadow-colored: 0px 4px 15px var(--tw-shadow-color), 0px 2px 4px var(--tw-shadow-color);
  // box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  @media (max-width: 500px) {
    height: 320px;
    padding: 15px;
  }

  .card-header {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .img {
    height: 88px;
    position: relative;
    width: 88px;
    @media (max-width: 500px) {
      height: 75px;
      width: 75px;
      margin-top: -6px;
    }
  }

  .card-header-info {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;
    position: relative;
    @media (max-width: 500px) {
      gap: 0px;
    }
  }
 
  .card-tags {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
  }

  .category-wrapper {
    align-items: center;
    background-color: #e5eeff;
    border-radius: 6px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 4px 16px;
    position: relative;
  }

  .category-item {
    color: rgb(0, 99, 247);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15.6px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    @media (max-width: 500px) {
      font-size: 10px;
    }
  }

  .status-wrapper {
    align-items: center;
    background-color: #e7fff3;
    border-radius: 6px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 4px 16px;
    position: relative;
  }

  .status-item {
    color: rgb(5, 166, 96);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15.6px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
    @media (max-width: 500px) {
      font-size: 10px;
    }
  }
  
  .title {
    color: #303133;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 41.6px;
    position: relative;
    white-space: nowrap;
    max-width: 343px;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 500px) {
      font-size: 21px;
      max-width: 241px;
    }
  }

  .sub-heading {
    align-items: center;
    display: flex;
    gap: 4px;
    height: 16px;
    position: relative;
  }

  .small-icon {
    height: 16px;
    position: relative;
    width: 16px;
  }

  .date {
    color: gray;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15.6px;
    margin-top: -1px;
    position: relative;
    @media (max-width: 500px) {
      font-size: 11px;
    }
  }

  .card-description {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 60px;
  }

  .p {
    align-self: stretch;
    color: #303133;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    margin-top: -1px;
    position: relative;
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }
  
  .avatar {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    margin-bottom: -1px;
    position: relative;
    @media (max-width: 500px) {
      gap: 2px;
    }
  }

  .avatar-image {
    height: 88px;
    object-fit: cover;
    position: relative;
    width: 88px;
    border-radius: 100%;
    //border: 1px solid #ffffff00;
    border: 1px solid $brand-primary;
    transition: border-color 0.2s;
    &:hover {
      border: 1px solid $brand-primary;
    }
    @media (max-width: 500px) {
      height: 75px;
      width: 75px;
    }
  }

  .author-info {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 3px;
    position: relative;
    width: 100%;
    border-radius: 10px;
    padding-left: 9px;
    width: 180px;
    .text, .text-2 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .text {
    color: #303133;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 18.2px;
    margin-top: -1px;
    position: relative;
    transition: color 0.2s;
    &:hover {
      color: $brand-primary;
    }
  }

  .time {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
    width: 100%;
  }

  .text-2 {
    color: gray;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18.2px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .drop-down-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    user-select: none; /* Disables text selection */
    justify-content: center;
  }

  .drop-down {
    align-items: center;
    background-color: #e5eff0;
    border: 2px solid;
    border-color: $brand-primary;
    border-radius: 24px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 10px 15px;
    position: relative;
    transition: background-color 0.2s;
    @media (max-width: 500px) {
      padding: 10px 10px;
    }
  }

  .drop-down:hover {
    background-color:  #c4e8eb;
  }

  .text-3 {
    color: $brand-secondary;
    
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 18.2px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  .card-footer {
    width: 100%;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    gap: 20px;
  }

  #visibility {
    color: white;
    border-radius: 6px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 4px 16px;
    position: relative;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15.6px;
    margin-top: -1px;
    white-space: nowrap;
    width: fit-content;
    @media (max-width: 500px) {
      font-size: 10px;
    }
  }
  #visibility.public {
    background-color: #86b9b9f0;
  }
  #visibility.draft {
    background-color: #b1b1b1;
  }

  .recruiting-dropdown {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 3px;
    border-radius: 3px;
    z-index: 1;
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s ease-in-out;
    animation-duration: 0.3s;
    animation-timing-function: ease;
    animation-fill-mode: both;
    white-space: nowrap;
    ul {
      justify-content: center;
      padding: 3px;
      margin: 0;
      }
    li {
      list-style-type: none;
      margin: 0;
      padding: 0 10px; /* Adjust the padding value as needed */
    }
  }
  
  .recruiting-dropdown {
    position: absolute;
    //top: 110%;
    bottom: 58%;
    display: block;
    opacity: 1;
    overflow: auto;
  }

  .recruiting-section {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: end;
    @media (max-width: 500px) {
      display: none;
    }
  }
}
