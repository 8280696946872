@import 'app/styling/variables.scss';
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
  }
@mixin inline-block() {
	display:-moz-inline-stack;
	display:inline-block;
	zoom:1;
	*display:inline;
}
.staff-entry{
	display: block;
	min-height: 520px;
	margin-bottom: 20px;
	z-index: 0;
	background: white;
	padding: 30px 80px;
	@media (max-width: $screen-mobile) {
		padding: 30px 20px;
	}
	--tw-shadow: 0px 4px 15px rgba(3, 24, 52, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.05);
	--tw-shadow-colored: 0px 4px 15px var(--tw-shadow-color), 0px 2px 4px var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
	@include border-radius(15px);
	.staff-content-wrapper {
		.staff-img{
			width: 150px;
			height: 150px;
			display: block;
			margin: 0 auto;
			margin-bottom: 30px;
			@include border-radius(50%);
		}
		.desc{
			h3{
				font-size: 24px;
				margin-bottom: 10px;
			}
			span{
				display: block;
				margin-bottom: 20px;
				font-size: 17px;
			}
			div {
				font-size: 17px;
			}
			Icon {
				border: 1px solid #ffffff00;
				cursor: pointer;
				&:hover{
					border: 1px solid $brand-secondary;
				}
			}
		}
		.colorlib-social-icons{
			li{
				margin-left: 0;
			}
		}
	}	
}

.colorlib-social-icons {
	margin: 0;
	padding: 0;
	bottom: 22px;
	position: absolute;
	li {
		margin: 0;
		padding: 0;
		list-style: none;
		@include inline-block;
		a {
			color: #4aca85;
			@include inline-block;
			padding-left: 5px;
			padding-right: 5px;
			i {
				font-size: 20px;
			}
		}
	}
}

.product-img,
.work-img,
.author-img,
.mobile-img,
.colorlib-video, 
.blog-img,
.colorlib-about-img,
.services-img,
.staff-img{
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
}

.simple-card{
	position: relative;
    min-height: 1px;
padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 40px;
	float: left;
	width: 100%;
}

@media (min-width: 992px) {
    .simple-card {
		width: 49.33333%;
	  }
}