.onboarding-flow-container {
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  
}
.onboarding-content {
  width: 100%;
  // max-width: 600px;
  min-height: 450px;
  margin: 0 auto;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  &.fade-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  &.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
  }
  &.fade-exit {
    opacity: 1;
    transform: translateX(0);
  }
  &.fade-exit-active {
    opacity: 0;
    transform: translateX(-100%);
  }
}
.navigation-buttons {
  margin-top: 20px;
  button {
    margin: 0 10px;
  }
}
.the-sentence-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.sentence-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.sentence-text {
  font-size: larger;
}
.input-box {
  margin: 10px;
}
.selection-box{
  margin: 10px;
}
.sentence-button-and-error{
  margin: 10px;
}
.the-introduction-container{
  display: flex;
  flex-direction: column;

  align-items: center;
}
.skill-expertise-interests-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.skills-column-divider{
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.portfolio-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

